import {
  Row,
  Col,
  Input,
  Space,
  Button,
  Menu,
  Dropdown,
  Avatar,
  Popover,
  Divider,
  Tag,
} from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  SearchOutlined,
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
  BellOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Route,
  useNavigate,
  Switch,
  HashRouter,
  Link,
  useLocation,
} from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import prof from "../img/user.svg";
import { MyContext } from "../Context";
import Axios from "axios";
import classNames from "../styles/components/navbar.module.scss";

//assets
import { RxTriangleUp } from "react-icons/rx";
import notificationIcon from "../assets/images/notification.svg";
import downArrowIcon from "../assets/images/downarrow.svg";
import searchIcon from "../assets/images/search.svg";
import { MARKET_ASSET_CLASSES } from "../config/constants";
import { GetSortOrder } from "../utils/FunctionTools";
import {
  APP_CODE,
  APP_COLOR,
  APP_FULL_LOGO,
  APP_FULL_LOGO_BLACK,
  APP_FULL_LOGO_GRADIENT,
} from "../config/appConfig";

import moreIcon from "../assets/images/sidebar/moreicon.svg";
import close from "../assets/images/sidebar/close.svg";
import { ReactComponent as Traders_img } from "../assets/images/mobileSidebar/traders.svg";
import { ReactComponent as Funds_img } from "../assets/images/mobileSidebar/funds.svg";
import { ReactComponent as Signals_img } from "../assets/images/mobileSidebar/signals.svg";
import { ReactComponent as Competitions_img } from "../assets/images/mobileSidebar/competitions.svg";
import { ReactComponent as Brokerages_img } from "../assets/images/mobileSidebar/brokerages.svg";
import { ReactComponent as Classrooms_img } from "../assets/images/mobileSidebar/classrooms.svg";
import { ReactComponent as Affiliate_img } from "../assets/images/mobileSidebar/affiliate.svg";
import { ReactComponent as Analysts_img } from "../assets/images/mobileSidebar/analysts.svg";
import { ReactComponent as People_img } from "../assets/images/mobileSidebar/people.svg";

const USER_INFO = gql`
  query getUser($user_id: ID, $uplineAppcode: String) {
    get_user(data: { user_id: $user_id, upline_appcode: $uplineAppcode }) {
      id
      name
      username
      email
      bio
      date_of_birth
      profile_image
      cover_image
      following_count
      my_follower_count
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
      long_bio
      user_type_id
      city
      achievements {
        id
        title
        description
        endorsed_users
      }
    }
  }
`;

const Nav = () => {
  const {
    tokenValid,
    setTrendClicked,
    navSearch,
    setNavSearch,
    setShowDropdown,
    showDrawer,
    setShowDrawer,
  } = useContext(MyContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [showDropdownL, setShowDropdownL] = useState(false);
  const [query, setQuery] = useState("");

  const [count, setCount] = useState(0);
  const handleMenuClick = () => {};
  const navigate = useNavigate();

  const [getAllUsersApi, setGetAllUsersApi] = useState([]);
  const [allUsername, setAllUsername] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);

  useEffect(() => {
    Axios.get(
      `https://connectionsapi.globalxchange.io/get_all_users_api?upline_appcode=${APP_CODE}`
    ).then((res) => {
      setGetAllUsersApi([...res.data.names]);
      setAllUsername([...res.data.usernames]);
      // setAllUserData()
    });
  }, []);

  var userInfo = useQuery(USER_INFO, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      uplineAppcode: APP_CODE,
    },
  });

  useEffect(() => {
    if (window.location.href.split("/").pop()) {
      setSelectedMenu(window.location.href.split("/").pop());
    } else {
      setSelectedMenu("home");
    }
  }, [window.location.href.split("/").pop()]);

  // let filteredUsers = getAllUsers.data
  //   ? getAllUsers.data.get_all_users.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return (item.name + item.username).toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  getAllUsersApi.filter((query) => allUsername.includes(query));

  let filteredUsersApi = getAllUsersApi
    ? getAllUsersApi.filter((item) => {
        const lowquery = query.toLowerCase();
        return item?.toLowerCase()?.indexOf(query) >= 0;
      })
    : "";

  // let filteredUsernames = allUsername
  //   ? allUsername.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.toLowerCase().indexOf(query) >= 0;
  //     })
  //   : "";

  //this is new
  // let filteredTrends = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  // let filteredTrendsApi = allTrends.data
  //   ? allTrends.data.trends.filter((item) => {
  //       const lowquery = query.toLowerCase();
  //       return item.name.toLowerCase().indexOf(lowquery) >= 0;
  //     })
  //   : "";

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        console.log("Close logout");
        setShowDropdownL(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   alert("You clicked outside of me!");
          setShowDropdownL(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  //   useEffect(() => {
  //     console.log("kjwekjfbwkefbw", showDropdown, count);

  //     window.addEventListener("click", () => {
  //       if (count < 2) {
  //         setCount(count + 1);
  //       } else {
  //         setCount(0);
  //       }
  //     });
  //   });

  //   useEffect(() => {
  //     if (showDropdown) {
  //       if (count === 2) {
  //         setShowDropdown(false);
  //         setCount(0);
  //       }
  //     }
  //   }, [count]);

  const conditionalNavRight = () => {
    if (tokenValid) {
      return <NavRightElement userInfo={userInfo} />;
    } else {
      if (window.innerWidth > 600) {
        return (
          <Col
            span={8}
            style={{
              minWidth: "12.5%",
              maxWidth: "12.5%",
              textAlign: "right",
            }}
          >
            <Space align="center" size="middle" className="spaceitem">
              <Link to="/login">
                <Button className="nav-button1">
                  <span className="button1text">Get Started</span>
                </Button>
              </Link>
              {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
            </Space>
          </Col>
        );
      } else {
        return (
          <div
            style={{
              color: "white",
              width: "27px",
              height: "27px",
              borderRadius: "50%",
              border: "1px solid #999999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SearchOutlined style={{ color: "#999999" }} />
          </div>
        );
      }
    }
  };

  const conditionalNav = () => {
    if (window.location.href.split("/").pop() !== "login") {
      return (
        <div onClick={(e) => setShowDrawer(false)}>
          <Row
            style={{
              padding: "15px 20px",
              justifyContent: "space-between",
              alignItems: "center",
              height: "73px",
            }}
          >
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                // minWidth: "12.5%",
                // maxWidth: "12.5%",
              }}
            >
              <Space>
                {window.innerWidth < 900 ? (
                  <img
                  src={moreIcon}
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDrawer(true);
                  }
                }
                />
                ) : (
                  ""
                )

                }
                {
                  window.innerWidth < 900 ? (
                    <div
                  style={{ width: "139px", paddingLeft: "15px"  }}
                  onClick={(e) => {
                    navigate("/home");
                    setTrendClicked(false);
                    setShowDropdown(false);
                  }}
                >
                  <img
                    className="hoverstyle1"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      // marginLeft: "-10px",
                    }}
                    src={tokenValid ? APP_FULL_LOGO : APP_FULL_LOGO_GRADIENT}
                    alt=""

                    // onClick={(e) => navigate("/home")}
                  />
                </div>
                  ) : (
                    <div
                  style={{ width: "139px"}}
                  onClick={(e) => {
                    navigate("/home");
                    setTrendClicked(false);
                    setShowDropdown(false);
                  }}
                >
                  <img
                    className="hoverstyle1"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      // marginLeft: "-10px",
                    }}
                    src={tokenValid ? APP_FULL_LOGO : APP_FULL_LOGO_GRADIENT}
                    alt=""

                    // onClick={(e) => navigate("/home")}
                  />
                </div>
                  )
                }
              </Space>
            </Col>

            {window.innerWidth > 900 ? (
              <SearchNav
                filteredUsersApi={filteredUsersApi}
                navSearch={navSearch}
                setNavSearch={setNavSearch}
                allUsername={allUsername}
                getAllUsersApi={getAllUsersApi}
                setQuery={setQuery}
                query={query}
              />
            ) : null}

            {conditionalNavRight()}
          </Row>
          {showDropdownL ? (
            <Row
              style={{
                padding: "0px 20px",
                position: "absolute",
                right: "142px",
                top: "14px",
                zIndex: 1,
              }}
            >
              <Col span={16} style={{ textAlign: "right" }}></Col>
              <Col span={8} style={{ textAlign: "right" }}>
                {tokenValid ? (
                  <Space align="center" size="middle">
                    <Button className="nav-button1" style={{ display: "none" }}>
                      <span className="button1text">Create</span>
                    </Button>
                    <Button className="nav-button2" style={{ display: "none" }}>
                      <span className="button2text">Refer</span>
                    </Button>
                    <div
                      ref={wrapperRef}
                      className="nav-userbutton-expand"
                      onClick={(e) => setShowDropdownL(!showDropdownL)}
                    >
                      <div
                        onClick={(e) => {
                          localStorage.clear();
                          window.location.reload();
                        }}
                        className="logoutText"
                        style={{
                          width: "100%",
                          borderRadius: "0px 0px 25px 25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "39px",
                          cursor: "pointer",
                        }}
                      >
                        Logout
                      </div>
                    </div>
                    {/* <BellOutlined style={{ fontSize: "20px", color: "#999999" }} /> */}
                  </Space>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  const navMenu = [
    {
      name: "Signals",
      icon: <Signals_img />,
      link: "home",
    },
    {
      name: "Traders",
      icon: <Traders_img />,
      link: "traders",
    },
    {
      name: "Funds",
      icon: <Funds_img />,
      link: "funds",
    },

    {
      name: "Competitions",
      icon: <Competitions_img />,
      link: "competitions",
    },
    {
      name: "Analysts",
      icon: <Analysts_img />,
      link: "analysts",
    },
    {
      name: "People",
      icon: <People_img />,
      link: "people",
    },
    {
      name: "Brokerages",
      icon: <Brokerages_img />,
      link: "brokerages",
    },
    {
      name: "Classrooms",
      icon: <Classrooms_img />,
      link: "classrooms",
    },
    {
      name: "Affiliate",
      icon: <Affiliate_img />,
      link: "affiliates",
    },
  ];

  return (
    <>
      {window.location.href.split("/").pop() !== "" ? conditionalNav() : ""}
      {window.innerWidth < 700 && showDrawer ? (
        <div className="loggedoutMenuDrawer">
          <div style = {{overflowY:"scroll"}}>
            <div className="lmdHeader">
              <img src={APP_FULL_LOGO_BLACK} alt="" />
              <img src={close} alt="" onClick={(e) => setShowDrawer(false)} />
            </div>
            <div className="lmdMenuWrapper">
              {navMenu.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      setSelectedMenu(item.link);
                      navigate(`/${item.link}`);
                      setShowDrawer(false);
                    }}
                    onMouseOver={() => setHoveredMenu(item.name)}
                    onMouseOut={() =>
                      window.location.href.split("/").pop() !== ""
                        ? setHoveredMenu(null)
                        : ""
                    }
                    className={
                      selectedMenu === item.link || hoveredMenu === item.name
                        ? "lmdMenuItemSelected"
                        : "lmdMenuItem"
                    }
                    // style={{
                    //   background:
                    //     selectedMenu === item.link || hoveredMenu === item.name
                    //       ? "rgba(237, 237, 237, 0.37)"
                    //       : "",
                    // }}
                  >
                    {item.icon ? React.cloneElement(item.icon) : ""}
                    <div
                      style={{
                        paddingLeft: "10px",
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lmdFooterLogin" onClick={(e) => navigate("/login")}>
            Login
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Nav;

const NavRightElement = ({ userInfo }) => {
  const { showDropdown, setShowDropdown } = useContext(MyContext);
  // console.log(userInfo?.data, "userInfo.data");
  return (
    <div className={classNames.navRightElement}>
      <div>
        <img src={notificationIcon} alt="notificationIcon" />
      </div>
      <div onClick={() => setShowDropdown(!showDropdown)}>
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          style={{ transform: showDropdown ? "rotate(180deg)" : "" }}
        />
      </div>
      <div>
        <Link
          to={
            userInfo?.data?.get_user?.username
              ? "/" + userInfo?.data?.get_user?.username
              : "/#"
          }
        >
          <img
            src={
              userInfo.data
                ? userInfo?.data?.get_user?.profile_image
                  ? userInfo?.data?.get_user?.profile_image
                  : prof
                : prof
            }
            alt="profile"
          />
        </Link>
      </div>
    </div>
  );
};

const SearchNav = ({
  filteredUsersApi,
  navSearch,
  setNavSearch,
  allUsername,
  getAllUsersApi,
  setQuery,
  query,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES[0]);

  return (
    <div className={classNames.searchNav}>
      <>
        <img
          src={searchIcon}
          alt="searchIcon"
          className={classNames.searchIcon}
          onClick={() => {
            // if (!location?.pathname?.includes("/funds")) {
            //   setNavSearch(true);
            // }
          }}
        />
        <input
          type="text"
          placeholder="Search ..."
          className={classNames.searchInput}
          onChange={(e) => setQuery(e.target.value)}
          style={{
            width:
              location?.pathname?.includes("/funds") ||
              location?.pathname?.includes("/home")
                ? "60%"
                : "",
            maxWidth:
              location?.pathname?.includes("/funds") ||
              location?.pathname?.includes("/home")
                ? "60%"
                : "",
          }}
          // onClick={() => {
          //   if (
          //     location?.pathname?.includes("/funds") ||
          //     location?.pathname?.includes("/home")
          //   ) {
          //     setNavSearch(true);
          //   }
          // }}
        />
      </>
      {/* {location?.pathname?.includes("/funds") ? (
        <div className={classNames.buttonsWrapper}>
          {MARKET_ASSET_CLASSES.sort(GetSortOrder("marketOrder")).map(
            (assetClassCard, i) => (
              <Link
                key={i}
                className={
                  assetClassCard === assetClass ? classNames.selectedType : ""
                }
                onClick={() => {
                  if (assetClassCard.marketEnable) {
                    setAssetClass(assetClassCard);
                  }
                }}
                to={
                  assetClassCard?.marketEnable
                    ? `/listings/${assetClassCard.name}`
                    : "/#"
                }
              >
                {assetClassCard?.insiderName}
              </Link>
            )
          )}
          
        </div>
      ) : location?.pathname?.includes("/vaults") ? (
        <div className={classNames.buttonsWrapper}>
          <div className={classNames.selectedType}>Private Equities</div>
          <div>Corporate Bonds</div>
          <div>Real Estate</div>
          <div>Mortgages</div>
        </div>
      ) : (
        <div className={classNames.buttonsWrapper}>
          <div
            style={{
              background:
                window.location.href.split("/").pop() === "people"
                  ? "black"
                  : "",
              color:
                window.location.href.split("/").pop() === "people"
                  ? "white"
                  : "",
            }}
            onClick={() => {
              navigate("/people");
            }}
          >
            People
          </div>
          <div>Analysts</div>
        </div>
      )} */}
      <div
        className={classNames.dropDown}
        style={{ display: navSearch ? "" : "none" }}
      >
        <RxTriangleUp />
        {filteredUsersApi?.length > 0 && query.length > 0 ? (
          filteredUsersApi?.map((eachUser) => {
            return (
              <Link
                className={classNames.eachUser}
                to={`/${allUsername[getAllUsersApi.indexOf(eachUser)]}`}
              >
                <img
                  src="https://insider.indianinvestor.com/static/media/user.5cdd10ce.svg"
                  alt="profile pic"
                />
                <span>{eachUser}</span>
              </Link>
            );
          })
        ) : (
          <div>Search user here..</div>
        )}
      </div>
    </div>
  );
};

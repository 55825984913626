import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Button,
} from "antd";

import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";

import { MyContext } from "../Context";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";
import { ReactComponent as Interactions_img } from "../img/nav-icons/Interactions.svg";

//assests
import { ReactComponent as Markets_img } from "../assets/images/sidebar/markets.svg";
import { ReactComponent as Listings_img } from "../assets/images/sidebar/listings.svg";
import { ReactComponent as Deals_img } from "../assets/images/sidebar/deals.svg";
import { ReactComponent as Communities_img } from "../assets/images/sidebar/communities.svg";
import { ReactComponent as Institute_img } from "../assets/images/sidebar/institute.svg";
import { ReactComponent as Stream_img } from "../assets/images/sidebar/stream.svg";
import { ReactComponent as Capital_img } from "../assets/images/sidebar/capital.svg";
import { APP_COLOR } from "../config/appConfig";

// New Assets

import { ReactComponent as Traders_img } from "../assets/images/sidebar/Traders.svg";
import { ReactComponent as Funds_img } from "../assets/images/sidebar/Funds.svg";
import { ReactComponent as Signals_img } from "../assets/images/sidebar/signals.svg";
import { ReactComponent as Competitions_img } from "../assets/images/sidebar/Competitions.svg";
import { ReactComponent as Brokerages_img } from "../assets/images/sidebar/Brokerages.svg";
import { ReactComponent as Classrooms_img } from "../assets/images/sidebar/Classrooms.svg";
import { ReactComponent as Affiliate_img } from "../assets/images/sidebar/Affiliate.svg";
import { ReactComponent as Analysts_img } from "../assets/images/sidebar/Analysts.svg";
import { ReactComponent as People_img } from "../assets/images/sidebar/People.svg";

const LoggedOutMenuSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const { userEmail } = useContext(MyContext);

  useEffect(() => {
    if (window.location.href.split("/").pop()) {
      setSelectedMenu(window.location.href.split("/").pop());
    } else {
      setSelectedMenu("home");
    }
  }, [window.location.href.split("/").pop()]);

  const navMenu = [
    {
      name: "Signals",
      icon: <Signals_img />,
      link: "home",
    },
    {
      name: "Traders",
      icon: <Traders_img />,
      link: "traders",
    },
    {
      name: "Funds",
      icon: <Funds_img />,
      link: "funds",
    },

    {
      name: "Competitions",
      icon: <Competitions_img />,
      link: "competitions",
    },
    {
      name: "Analysts",
      icon: <Analysts_img />,
      link: "analysts",
    },
    {
      name: "People",
      icon: <People_img />,
      link: "people",
    },
    {
      name: "Brokerages",
      icon: <Brokerages_img />,
      link: "brokerages",
    },
    {
      name: "Classrooms",
      icon: <Classrooms_img />,
      link: "classrooms",
    },
    {
      name: "Affiliate",
      icon: <Affiliate_img />,
      link: "affiliates",
    },
  ];

  return (
    <Col
      span={4}
      style={{
        // background: "#E5E5E5",
        padding: "10px",
        paddingTop: "20px",
        // height: "90vh",
        height: "100%",
        // borderRight: "0.5px solid #EDEDED",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        // border: "2px solid red",
      }}
    >
      <div className="leftBottomBtns">
        <Link className="nav-button1" to="/login">
          Login
        </Link>
      </div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
          height: "calc(100% - 79px)",
          overflowY: "scroll",
        }}
      >
        {navMenu.map((item, index) => {
          if (index <= 5) {
            return (
              <Link to={`/${item.link}`}>
                <div
                  onClick={(e) => setSelectedMenu(item.link)}
                  onMouseOver={() => setHoveredMenu(item.name)}
                  onMouseOut={() =>
                    window.location.href.split("/").pop() !== ""
                      ? setHoveredMenu(null)
                      : ""
                  }
                  className="nav-item"
                  style={{
                    background:
                      selectedMenu === item.link || hoveredMenu === item.name
                        ? "#302F2F"
                        : "",
                  }}
                >
                  {item.icon
                    ? React.cloneElement(item.icon, {
                        fill: "#999999",
                      })
                    : ""}
                  <div
                    className="nav-menu-text"
                    style={{
                      color: "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              </Link>
            );
          }
        })}

        <hr style={{ border: "0.5px solid rgba(153, 153, 153, 0.5)" }} />

        {navMenu.map((item, index) => {
          if (index > 5 && index < 9) {
            return (
              <Link to={`/${item.link}`}>
                <div
                  onClick={(e) => setSelectedMenu(item.link)}
                  onMouseOver={() => setHoveredMenu(item.name)}
                  onMouseOut={() =>
                    window.location.href.split("/").pop() !== ""
                      ? setHoveredMenu(null)
                      : ""
                  }
                  className="nav-item"
                  style={{
                    background:
                      selectedMenu === item.link || hoveredMenu === item.name
                        ? "#302F2F"
                        : "",
                  }}
                >
                  {item.icon
                    ? React.cloneElement(item.icon, {
                        fill: "#999999",
                      })
                    : ""}

                  <div
                    className="nav-menu-text"
                    style={{
                      color: "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              </Link>
            );
          }
        })}
      </Space>
    </Col>
  );
};

export default LoggedOutMenuSidebar;

import React, { useState, useEffect } from "react";
import styles from "./trends.module.scss";
import Axios from "axios";

const Alltrends = () => {
  const [allTrends, setAllTrends] = useState([]);
  useEffect(() => {
    Axios.post("https://connectionsapi.globalxchange.io/graphql", {
      query: `
    {
      trends(skip: 0, upline_appcode: "dgpayments", limit: 10) {
        name, post_count
      }
    }
  `,
    })
      .then(({ data }) => {
        if (data.data.trends) {
          setAllTrends(data.data.trends);
          console.log(data.data.trends);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.trendsSection}>
      <div className={styles.trendHeader}>Trending Topics</div>
      {allTrends?.map((item) => {
        return (
          <div className={styles.trendItem}>
            <div>#{item?.name}</div>
            <div>{item?.post_count}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Alltrends;

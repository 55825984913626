import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "./loginStyle.module.scss";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Icon,
  Modal,
  Avatar,
} from "antd";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../../config";
import { MyContext } from "../../Context";
import isEmail from "validator/lib/isEmail";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "apollo-boost";
import { BankContext } from "../../context/Context";
import * as jose from "jose";
import {
  APP_CODE,
  APP_COLOR,
  APP_FULL_LOGO,
  APP_FULL_LOGO_GRADIENT,
  APP_LOGO_WHITE,
} from "../../config/appConfig";
import { CameraOutlined } from "@material-ui/icons";
import { UploadOutlined } from "@ant-design/icons";

const GET_USER = gql`
  query ($email: ID!) {
    get_user(data: { email: $email }) {
      id
      username
      name
      bio
      email
      profile_image
      cover_image
    }
  }
`;

const Login = () => {
  const { step, setStep, insiderReg, setInsiderReg, tokenValid } =
    useContext(MyContext);
  const { email, setEmail, setIdToken, setAccessToken } =
    useContext(BankContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [rUsername, setRusername] = useState("");
  const [rEmail, setRemail] = useState("");
  const [rPassword, setRpassword] = useState("");
  const [rPassword1, setRpassword1] = useState("");
  const [visibleVerify, setVisibleVerify] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [regStep, setRegStep] = useState(0);
  const [userType, setUserType] = useState("");
  const [fullName, setFullName] = useState("");
  const [customUname, setCustomUname] = useState("");
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const [errorMsg, setErrorMsg] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
  const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const onProfileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // console.log(event.target.files[0]);
      // setSelectedProfileImg();

      let reader1 = new FileReader();
      reader1.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader1.readAsDataURL(event.target.files[0]);
      uploadProfileImage(event.target.files[0]);
    }
  };

  const uploadProfileImage = async (file) => {
    setLoading(true);

    const fileName = `${new Date().getTime()}${file.name.substr(
      file.name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const newfile = renameFile(file, fileName);
    formData.append("files", newfile);
    const path_inside_brain = "root/";

    // const fileName = `${new Date().getTime()}${file.name.substr(
    //   file.name.lastIndexOf(".")
    // )}`;
    // const formData = new FormData();
    // formData.append("file", file);
    // const path_inside_brain = "root/";
    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // setImage(data.payload.url);
    setProfileImage(data.payload.url);
    setLoading(false);
  };

  const handleLogin = () => {
    setEmail(username);
    if (isEmail(username) && password !== "") {
      // message.loading("Authenticating...");
      setLoading(true);

      Axios.post(`https://gxauth.apimachine.com/gx/user/auth/login`, {
        email: username,
        password: password,
      })
        .then(({ data }) => {
          localStorage.setItem("userData", JSON.stringify(data.user));
          if (data.status) {
            // console.log("inside 1st api true");
            Axios.get(
              `https://connectionsapi.globalxchange.io/user_upline_appcode?email=${username}&upline_appcode=${APP_CODE}`
            ).then((res) => {
              if (res.data.status) {
                // console.log("inside 2nd api true");
                setLoading(false);
                message.destroy();
                message.success("Login Successfull");
                localStorage.setItem("access_token", data.accessToken);
                localStorage.setItem("user_account", username);
                localStorage.setItem("loginObj", JSON.stringify(data));
                localStorage.setItem("token", data.idToken);

                navigate("/");
                window.location.reload();
              } else {
                // localStorage.setItem("userId", data?.user?.username);
                // localStorage.clear();
                setInsiderReg(true);
                setRegStep(1);
                // setStep(8);
                // navigate("/getstarted", {
                //   state: { loginEmail: username, loginPassword: password },
                // });
              }
            });
          } else {
            setErrorMsg(data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          console.log(err);
          message.destroy();
          setErrorMsg(err.message);
          message.error(err.message, 2);
          setLoading(false);
          if (err.message === "User is not confirmed.") {
            setVisibleVerify(true);
          }
        });
    } else {
      message.destroy();
      message.error("Please enter Email & password");
    }
  };

  const handleSignup = () => {
    if (
      rUsername !== "" &&
      rEmail !== "" &&
      rPassword !== "" &&
      rPassword1 !== ""
    ) {
      if (isEmail(rEmail)) {
        if (rPassword === rPassword1) {
          message.loading("Signing you up...");
          Axios.post(`https://gxauth.apimachine.com/gx/user/signup`, {
            username: rUsername,
            email: rEmail,
            password: rPassword,
            ref_affiliate: "1",
            account_type: "Personal",
            signedup_app: "Brain",
          }).then((res) => {
            if (res.data.status === true) {
              // message.destroy();
              // message.success("Successfully Registered, Verify to continue.");
              setVisibleVerify(true);
            } else {
              message.destroy();
              message.error(res.data.message);
            }
          });
        }
      } else {
        message.error("invalid email");
      }
    } else {
      message.error("fill all fields");
    }
  };

  const handleVerify = () => {
    if (verificationCode !== "" && isEmail(rEmail)) {
      Axios.post(`https://gxauth.apimachine.com/gx/user/confirm`, {
        email: rEmail,
        code: verificationCode,
      }).then((res) => {
        if (res.data.status) {
          setVisibleVerify(false);
          message.destroy();
          message.success(
            "Your Account gas been verified. Please login to continue."
          );
        }
      });
    }
  };

  const handleNext = (type) => {
    setUserType(type);
    setRegStep(2);
  };

  const handleRegisterToApp = () => {
    if (customUname && fullName && profileImage && userType) {
      setLoading(true);
      Axios.post(`https://connectionsapi.globalxchange.io/register_user`, {
        username: customUname,
        email: username
          ? username
          : localStorage.getItem("nvestBankLoginAccount"),
        name: fullName,
        profile_image: profileImage,
        upline_appcode: APP_CODE,
        additional_data: {
          user_Type: userType,
        },
      }).then((res) => {
        if (res.data.status) {
          setLoading(false);
          setRegStep(0);
          message.destroy();
          message.success("Registration Successful. Please Login Again.");
        } else {
          message.error("Registration Failed");
        }
      });
    }
  };

  const conditionalRightSection = () => {
    if (regStep === 1) {
      return (
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            padding: "80px 50px",
          }}
        >
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Type Of User?</h1>
              <h4 className={styles.subTitle}>
                How will you be using your account?
              </h4>
            </div>
            <div className={styles.form}>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Investor")}
              >
                Investor
              </div>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Fund Manager")}
              >
                Fund Manager
              </div>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Analyst")}
              >
                Analyst
              </div>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Trader")}
              >
                Trader
              </div>
            </div>
          </div>
        </Col>
      );
    } else if (regStep === 2) {
      return (
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            padding: "80px 50px",
          }}
        >
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Name & Profile Pic</h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10vh 0px",
                }}
              >
                <label for="image1">
                  <input
                    accept="image/*"
                    onChange={onProfileImageChange}
                    type="file"
                    name="image1"
                    id="image1"
                    style={{ display: "none" }}
                  />

                  <Avatar
                    src={profileImage}
                    style={{
                      cursor: "pointer",
                    }}
                    icon={<UploadOutlined style={{ fontSize: "100px" }} />}
                    size={200}
                  />
                </label>
              </div>

              <div>
                <Input
                  style={{ padding: "0px 30px" }}
                  className="login-input"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <br />
                <br />
                <Input
                  style={{ padding: "0px 30px" }}
                  className="login-input"
                  placeholder="Username"
                  value={customUname}
                  onChange={(e) => setCustomUname(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "0px",
            }}
          >
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="login-button"
              onClick={(e) => handleRegisterToApp()}
              style={{
                background: APP_COLOR,
                border: "none",
              }}
            >
              Complete
            </Button>
            <br />

            <Button
              onClick={(e) => setRegStep(1)}
              className="signup-button"
              // loading={loading ? true : false}
              // type="submit"
              type="ghost"
            >
              Go Back
            </Button>
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            padding: "0px 50px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <img
              src={tokenValid ? APP_FULL_LOGO : APP_FULL_LOGO_GRADIENT}
              alt=""
              style={{ width: "100%" }}
            />

            <form
              onSubmit={handleLogin}
              style={{ width: "100%", marginTop: "50px" }}
            >
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br /> <br />
              <Input
                style={{ padding: "0px 30px" }}
                className="login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </form>
            {!errorMsg === "" ? (
              <p
                style={{ color: "red", fontWeight: "500", paddingTop: "20px" }}
              >
                Invalid Credentials
              </p>
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "30px",
            }}
          >
            <Button
              loading={loading ? true : false}
              // type="submit"
              type="primary"
              className="login-button"
              onClick={handleLogin}
            >
              Login
            </Button>
            <br />
            <Link to="/getstarted" className="signup-button">
              <Button
                className="signup-button"
                // loading={loading ? true : false}
                // type="submit"
                type="ghost"
              >
                Get Started
              </Button>
            </Link>
            <br />
            <br />
            <Link to="/getstarted/preregistered">
              <div className="already-registered">
                Click Here If You Were Pre-Registered
              </div>
            </Link>
          </div>
        </Col>
      );
    }
  };

  return (
    <>
      <Row>
        <Col
          span={16}
          // className="side-bg"
          style={{
            height: "100vh",
            // background: "#009EFC",
            // background: "line#302F2Far-gradient(270deg, #4CAF50 0%, #302F2F 100%)",
            background: `${APP_COLOR}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={APP_LOGO_WHITE}
            alt=""
            // width="0%"
            style={{ opacity: "1" }}
          />
        </Col>
        {conditionalRightSection()}
      </Row>

      {/* Verify with OTP Modal */}

      <Modal
        centered
        closable={false}
        // title="Basic Modal"
        visible={visibleVerify}
        onCancel={(e) => setVisibleVerify(false)}
        footer={null}
        // bodyStyle={{ padding: "0px" }}
      >
        <h2>Signup Verification</h2>
        <p>
          You have received a verification code in your registered Email. Please
          type both Email and the code and click on verify to proceed
        </p>
        <Input
          placeholder="Enter Registered Email"
          style={{ marginBottom: "10px" }}
          type="text"
          value={rEmail}
          onChange={(e) => setRemail(e.target.value)}
        />
        <Input
          placeholder="Enter Verification Code"
          style={{ marginBottom: "10px" }}
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button block type="primary" size="large" onClick={handleVerify}>
          V E R I F Y
        </Button>
      </Modal>
    </>
  );
};

export default Login;

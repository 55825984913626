import React from "react";
import LoggedOutMenuSidebar from "../components/LoggedOutMenuSidebar";
import { Col, Row } from "antd";
import Alltrends from "../components/AllTrends";

const ComingSoon = () => {
  return (
    <>
      <Row style={{ height: "calc(100% - 105px)" }}>
        {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}

        <Col
          xs={24}
          sm={14}
          style={{
            // borderRight: "0.5px solid #EDEDED",
            // background: "#ECF0F1",
            // background: "white",
            overflowY: "scroll",
            height: "98vh",
            padding: " 1px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          {window.location.href.split("/").pop().toUpperCase()} is Coming Soon
        </Col>

        <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
          <Alltrends />
        </Col>
      </Row>
    </>
  );
};

export default ComingSoon;

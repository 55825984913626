import { Col, Empty, Spin, Tag, Divider, Card } from "antd";
import React, { useState, useContext, useEffect } from "react";
import MenuSidebar from "../components/MenuSidebar";
import CreatePostComponent from "../components/CreatePostComponent";

import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { MyContext } from "../Context";
import PostComponent from "../components/PostComponent";
import ExplorePost from "../components/Minicomponents/ExplorePost";
import "../styles/pages/home.css";
import { cardElements, homeRight } from "../assets/data/home";

//assest
import prof from "../img/user.svg";
import rightArrow from "../assets/images/dropdownrightarrow.svg";
import { APP_CODE, APP_COLOR_LIGHT } from "../config/appConfig";

const ALL_TRENDS = gql`
  query Trends($uplineAppcode: String) {
    trends(upline_appcode: $uplineAppcode) {
      id
      name
      post_count
    }
  }
`;

const Home = () => {
  const {
    newsFeed,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    selectedTrend,
    setSelectedTrend,
    setTrendClicked,
    showMenu,
    showTrend,
    setShowTrend,
  } = useContext(MyContext);

  const allTrends = useQuery(ALL_TRENDS, {
    variables: {
      uplineAppcode: APP_CODE,
    },
  });

  const showConditionalPosts = () => {
    console.log("newsfeed", newsFeed.data);
    if (resTrendPosts.data && trendClicked) {
      // console.log("jhkjhkjhk", value.resTrendPosts.data);
      return resTrendPosts.data ? (
        resTrendPosts.data.user_choosen_trend
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <p>No Posts</p>
        </div>
      );
    } else if (newsFeed.data && !trendClicked) {
      if (newsFeed.data.get_my_home_posts.length > 0) {
        return newsFeed.data.get_my_home_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ));
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty
              description={
                <span>
                  Either you are not subscribed to anyone <br />
                  or there are no posts to show
                </span>
              }
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  const showTrends = () => {
    if (allTrends.data) {
      if (allTrends.data.trends.length > 0) {
        return (
          allTrends.data.trends
            // .sort((a, b) => (a.post_count < b.post_count ? 1 : -1))
            .map((item, index) => {
              if (index < 10 && item.post_count > 0) {
                return (
                  <div style={{ marginTop: "10px" }} key={item?.name + index}>
                    <p
                      onClick={() => {
                        setTrendClicked(true);
                        setSelectedTrend({
                          name: item.name,
                          count: item.post_count,
                        });
                        // setTrendPostCount(item.post_count);
                        getPostsOfTrend({
                          variables: {
                            id: item.id,
                            user_id: localStorage.getItem("pulse_user_id"),
                          },
                        });
                      }}
                      style={{
                        margin: "0px",
                        cursor: "pointer",
                        // padding: "5px",
                        // paddingLeft: "25px"
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 20px",
                      }}
                    >
                      <span>#{item.name}</span>
                      <span>{item.post_count}</span>
                    </p>
                    {/* {index !== 9 ? (
                      allTrends.data.trends.length !== index + 1 ? (
                        <Divider dashed style={{ margin: "5px 0px" }} />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                    {/* {index !== 9 ? (
                    <Divider dashed style={{ margin: "5px 0px" }} />
                  ) : (
                    <Empty />
                  )} */}
                  </div>
                );
              }
            })
        );
      } else {
        return <Empty />;
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  useEffect(() => {
    console.log("showMenu: ", showMenu, "showTrend: ", showTrend);
  }, [showMenu, showTrend]);

  return (
    <>
      {!showMenu && !showTrend ? (
        <Col
          xs={24}
          sm={14}
          style={{
            // display: showMenu ? "none" : "block",
            borderRight: "0.5px solid #EDEDED",
            // background: "#ECF0F1",
            // background: "white",
            overflowY: "scroll",
            height: "98vh",
            padding: " 1px",
          }}
        >
          {!trendClicked ? (
            ""
          ) : (
            <div className="trend-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  #
                </span>
                &nbsp;
                <h2 style={{ margin: "0px", color: "white" }}>
                  {selectedTrend.name}
                </h2>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag
                  color="#fffff"
                  style={{ color: "black" }}
                  className="shadow-down"
                >
                  Post Count: {selectedTrend.count}
                </Tag>
              </div>
            </div>
          )}
          <div style={{ padding: " 20px" }}>
            <ExplorePost />
            {showConditionalPosts()}
          </div>
        </Col>
      ) : showMenu ? (
        <MenuSidebar />
      ) : (
        ""
      )}
      {window.innerWidth > 900 ? (
        <Col xs={24} sm={6}>
          <HomeRightElements showTrends={showTrends} />
          {/* <Card
            title="Recent Trends"
            bodyStyle={{
              padding: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {showTrends()}
          </Card> */}
        </Col>
      ) : showTrend ? (
        <Col xs={24} sm={6}>
          {/* <Card
            title="Recent Trends"
            bodyStyle={{
              padding: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {showTrends()}
          </Card> */}
          <HomeRightElements showTrends={showTrends} />
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;

const HomeRightElements = ({ showTrends }) => {
  return (
    <div className="rightDiv">
      {homeRight.map((eachElement, i) => {
        return (
          <EachElement
            element={eachElement}
            showTrends={showTrends}
            key={eachElement + i}
          />
        );
      })}
    </div>
  );
};

const EachElement = ({ element, showTrends }) => {
  const [elementOpen, setElementOpen] = useState(false);
  return (
    <div
      className="eachRightElement"
      onClick={() => setElementOpen(!elementOpen)}
    >
      {elementOpen && element === "Trending Topics" ? (
        <TrendingTopic showTrends={showTrends} />
      ) : elementOpen && element === "Winners & Flops" ? (
        <WinnersAndFlops />
      ) : (
        element
      )}
    </div>
  );
};

const TrendingTopic = ({ showTrends }) => {
  // console.log(allTrends.data, "allTrends.data");
  return (
    <div className="trendingTopic">
      <div className="rightheading">Trending Topics</div>
      <div className="trendingTags">{showTrends()}</div>
      <div className="trendingAll">
        <span>See All Trending Topics </span>
        <img src={rightArrow} alt="rightArrow" />
      </div>
    </div>
  );
};

const WinnersAndFlops = () => {
  const [selected, setSelected] = useState("Winners");
  const [selectedCard, setSelectedCard] = useState("Companies");

  function changeColor(color) {
    var div = document.getElementById("myDiv");
    div.style.color = color;
  }
  return (
    <div className="winnersflops">
      <div className="rightheading">
        <div
          style={{ opacity: selected === "Winners" ? "" : "0.5" }}
          // onClick={(e) => setSelected(e.target.innerText)}
        >
          Winners
        </div>
        <div
          style={{ opacity: selected === "Flops" ? "" : "0.5" }}
          // onClick={(e) => setSelected(e.target.innerText)}
        >
          Flops
        </div>
      </div>
      <div className="rightCardElements">
        {cardElements.map((eachItem, i) => {
          return (
            <div
              className={
                eachItem === selectedCard
                  ? "rightCardElementsGreen"
                  : "rightCardElementsWhite"
              }
              key={eachItem + i}
              onClick={(e) => setSelectedCard(e.target.innerText)}
            >
              {eachItem}
            </div>
          );
        })}
      </div>
      <div className="rightCardElementsValue">
        <div>
          <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
            <img
              src={prof}
              alt="prof"
              style={{ height: "22px", width: "22px", borderRadius: "50%" }}
            />
            <span style={{ fontSize: "1rem" }}>@Apple</span>
          </div>
          <div
            style={{
              height: "60%",
              background: APP_COLOR_LIGHT,
              borderRadius: "5px",
              color: "#302F2F",
              padding: "7px",
              fontSize: "0.8rem",
            }}
          >
            +3.31%
          </div>
        </div>
        <div>
          <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
            <img
              src={prof}
              alt="prof"
              style={{ height: "22px", width: "22px", borderRadius: "50%" }}
            />
            <span style={{ fontSize: "1rem" }}>@Apple</span>
          </div>
          <div
            style={{
              height: "60%",
              background: APP_COLOR_LIGHT,
              borderRadius: "5px",
              color: "#302F2F",
              padding: "7px",
              fontSize: "0.8rem",
            }}
          >
            +3.31%
          </div>
        </div>
        <div>
          <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
            <img
              src={prof}
              alt="prof"
              style={{ height: "22px", width: "22px", borderRadius: "50%" }}
            />
            <span style={{ fontSize: "1rem" }}>@Apple</span>
          </div>
          <div
            style={{
              height: "60%",
              background: APP_COLOR_LIGHT,
              borderRadius: "5px",
              color: "#302F2F",
              padding: "7px",
              fontSize: "0.8rem",
            }}
          >
            +3.31%
          </div>
        </div>
      </div>
    </div>
  );
};

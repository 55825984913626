export const APP_CODE = "dgpayments";
export const APP_NAME = "Signals";
export const APP_FULL_LOGO_GRADIENT = require("../assets/images/appData/full_logo_gradient.svg");
export const APP_FULL_LOGO_BLACK = require("../assets/images/appData/app_full_grad_black.svg");
export const APP_FULL_LOGO = require("../assets/images/appData/full_logo.svg");
export const APP_LOGO_WHITE = require("../assets/images/appData/half_logo_white.svg");
export const APP_LOGO_COLOR = require("../assets/images/appData/half_logo_colour.svg");
// export const APP_DESCRIPTION = "Signals is a platform for trading signals";
export const APP_COLOR = "#302F2F";
export const APP_COLOR_LIGHT = "rgba(48, 47, 47, 0.2)";

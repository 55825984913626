import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Input,
  Avatar,
  Spin,
  Popover,
  Divider,
  Tag,
  Col,
  Row,
  Space,
  Button,
  Modal,
} from "antd";

import {
  Link,
  useNavigate,
  useLocation,
  Switch,
  Route,
  Outlet,
} from "react-router-dom";

import { MyContext } from "../Context";

import { ReactComponent as Home_img } from "../img/nav-icons/Home.svg";
import { ReactComponent as Saved_img } from "../img/nav-icons/Saved.svg";
import { ReactComponent as Chats_img } from "../img/nav-icons/Chats.svg";
import { ReactComponent as Conn_img } from "../img/nav-icons/Connections.svg";
import { ReactComponent as Profile_img } from "../img/nav-icons/Profile.svg";
import { ReactComponent as Subs_img } from "../img/nav-icons/Subscriptions.svg";
import { ReactComponent as Vaults_img } from "../img/nav-icons/Vaults.svg";
import { ReactComponent as Drops_img } from "../img/nav-icons/Drops.svg";
import { ReactComponent as Interactions_img } from "../img/nav-icons/Interactions.svg";

//assests
import { ReactComponent as Markets_img } from "../assets/images/sidebar/markets.svg";
import { ReactComponent as Listings_img } from "../assets/images/sidebar/listings.svg";
import { ReactComponent as Deals_img } from "../assets/images/sidebar/deals.svg";
import { ReactComponent as Communities_img } from "../assets/images/sidebar/communities.svg";
import { ReactComponent as Institute_img } from "../assets/images/sidebar/institute.svg";
import { ReactComponent as Stream_img } from "../assets/images/sidebar/stream.svg";
import { ReactComponent as Capital_img } from "../assets/images/sidebar/capital.svg";

import { ReactComponent as Traders_img } from "../assets/images/sidebar/Traders.svg";
import { ReactComponent as Funds_img } from "../assets/images/sidebar/Funds.svg";
import { ReactComponent as Signals_img } from "../assets/images/sidebar/signals.svg";
import { ReactComponent as Competitions_img } from "../assets/images/sidebar/Competitions.svg";
import { ReactComponent as Brokerages_img } from "../assets/images/sidebar/Brokerages.svg";
import { ReactComponent as Classrooms_img } from "../assets/images/sidebar/Classrooms.svg";

//New Icons

import CreatePostComponent from "./CreatePostComponent";
import { APP_COLOR, APP_COLOR_LIGHT } from "../config/appConfig";

const MenuSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const {
    tokenValid,
    userEmail,
    setTrendClicked,
    showCreatePost,
    setShowCreatePost,
  } = useContext(MyContext);

  useEffect(() => {
    setSelectedMenu(window.location.href.split("/").pop());
  }, [window.location.href.split("/").pop()]);

  useEffect(() => {
    console.log(tokenValid, "iwuedikuwjebcjwegfckw");
  }, [tokenValid]);

  // useEffect(() => {
  //   console.log(location, "kbwcdkhwbed");
  // });

  // const conditionalHighlight = () => {
  //   if(window.location.href.split("/").pop()){

  //   }
  // }

  const navMenu = [
    {
      name: "Feed",
      icon: <Home_img />,
      link: "home",
    },
    {
      name: "Traders",
      icon: <Traders_img />,
      link: "traders",
    },
    {
      name: "Funds",
      icon: <Funds_img />,
      link: "funds",
    },
    {
      name: "Signals",
      icon: <Signals_img />,
      link: "signals",
    },
    {
      name: "Competitions",
      icon: <Competitions_img />,
      link: "competitions",
    },
    {
      name: "Brokerages",
      icon: <Brokerages_img />,
      link: "brokerages",
    },
    {
      name: "Classrooms",
      icon: <Classrooms_img />,
      link: "classroom",
    },
    {
      name: "For Analyts",
      link: "analyts",
    },
    {
      name: "For Affiliates",
      link: "affiliates",
    },
    {
      name: "For Fund Managers",
      link: "managers",
    },
  ];

  return (
    <>
      {/* {window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") ||
      tokenValid ? ( */}
      <Col
        span={
          window.innerWidth > 900
            ? window.location.pathname.replace(/^\/([^\/]*).*$/, "$1") ||
              tokenValid
              ? 4
              : 4
            : 24
        }
        style={{
          // background: "#E5E5E5",
          padding: "10px",
          paddingTop: "20px",
          // height: "98vh",
          height: "100%",
          borderRight: "0.5px solid #EDEDED",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
        }}
      >
        <div className="leftBottomBtns">
          <div
            className="nav-button1"
            style={{ background: APP_COLOR }}
            onClick={(e) => setShowCreatePost(true)}
          >
            Create
          </div>
          {/* <Link className="nav-button2" to="#">
              Affiliates
            </Link> */}
        </div>
        <Space
          direction="vertical"
          style={{
            width: "100%",
            height: "calc(100% - 79px)",
            overflowY: "scroll",
          }}
        >
          {navMenu.map((item, index) => {
            if (index <= 4) {
              return (
                <div
                  onClick={(e) => {
                    if (index === 0 || index === 2) {
                      tokenValid
                        ? navigate(`/${item.link}`)
                        : navigate("/login");
                    }
                  }}
                  onMouseOver={() => setHoveredMenu(item.link)}
                  onMouseOut={() =>
                    window.location.href.split("/").pop() !== ""
                      ? setHoveredMenu(null)
                      : ""
                  }
                  className="nav-item"
                  style={{
                    background:
                      selectedMenu === item.link || hoveredMenu === item.link
                        ? APP_COLOR_LIGHT
                        : "",
                  }}
                >
                  {item.icon
                    ? React.cloneElement(item.icon, {
                        fill:
                          selectedMenu === item.link ||
                          hoveredMenu === item.link
                            ? APP_COLOR
                            : "#999999",
                      })
                    : ""}

                  <div
                    className="nav-menu-text"
                    style={{
                      color:
                        selectedMenu === item.link || hoveredMenu === item.link
                          ? APP_COLOR
                          : "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            }
          })}

          <hr style={{ border: "1px solid #EDEDED" }} />

          {navMenu.map((item, index) => {
            if (index > 4 && index < 7) {
              return (
                <div
                  onClick={(e) => navigate(`/${item.link}`)}
                  onMouseOver={() => setHoveredMenu(item.link)}
                  onMouseOut={() =>
                    window.location.href.split("/").pop() !== ""
                      ? setHoveredMenu(null)
                      : ""
                  }
                  className="nav-item"
                  style={{
                    background:
                      selectedMenu === item.link || hoveredMenu === item.link
                        ? APP_COLOR_LIGHT
                        : "",
                  }}
                >
                  {item.icon
                    ? React.cloneElement(item.icon, {
                        fill:
                          selectedMenu === item.link ||
                          hoveredMenu === item.link
                            ? APP_COLOR
                            : "#999999",
                      })
                    : ""}

                  <div
                    className="nav-menu-text"
                    style={{
                      color:
                        selectedMenu === item.link || hoveredMenu === item.link
                          ? APP_COLOR
                          : "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            }
          })}

          <hr style={{ border: "1px solid #EDEDED" }} />

          {navMenu.map((item, index) => {
            if (index > 6) {
              return (
                <div
                  onClick={(e) => {
                    if (index === 8) {
                      window.open("https://viral.group/explorer", "_blank");
                    } else if (index === 9) {
                      window.open("https://trade.stream/", "_blank");
                    }
                  }}
                  onMouseOver={() => setHoveredMenu(item.link)}
                  onMouseOut={() =>
                    window.location.href.split("/").pop() !== ""
                      ? setHoveredMenu(null)
                      : ""
                  }
                  className="nav-item"
                  style={{
                    background:
                      selectedMenu === item.link || hoveredMenu === item.link
                        ? APP_COLOR_LIGHT
                        : "",
                  }}
                >
                  {item.icon
                    ? React.cloneElement(item.icon, {
                        fill:
                          selectedMenu === item.link ||
                          hoveredMenu === item.link
                            ? APP_COLOR
                            : "#999999",
                        height: "30px",
                        width: "30px",
                      })
                    : ""}

                  <div
                    className="nav-menu-text"
                    style={{
                      color:
                        selectedMenu === item.link || hoveredMenu === item.link
                          ? APP_COLOR
                          : "#999999",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              );
            }
          })}

          {window.innerWidth > 900 ? (
            ""
          ) : (
            <Button
              block
              type="primary"
              size="large"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </Button>
          )}
        </Space>
      </Col>

      <Modal
        width={800}
        footer={null}
        // title="Basic Modal"
        open={showCreatePost}
        // onOk={handleOk}
        onCancel={(e) => setShowCreatePost(false)}
      >
        <CreatePostComponent setShowCreatePost={setShowCreatePost} />
      </Modal>
    </>
  );
};

export default MenuSidebar;

import React, { useContext } from "react";

import styles from "./analysts.module.scss";

//packages
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "react-loading-skeleton";
import LoggedOutMenuSidebar from "../../components/LoggedOutMenuSidebar";
import { MyContext } from "../../Context";
import { APP_CODE, APP_LOGO_COLOR } from "../../config/appConfig";

const People = () => {
  const { setShowDrawer } = useContext(MyContext);

  const GET_ALL_USERS = gql`
    query ($upline_appcode: String, $user_type: String) {
      get_all_user_types(
        data: { upline_appcode: $upline_appcode, user_type: $user_type }
      ) {
        email
        username
        name
        bio
        profile_image
        following_count
        tiers_count
        posts_count
        my_follower_count
      }
    }
  `;

  const allUsers = useQuery(GET_ALL_USERS, {
    variables: {
      upline_appcode: APP_CODE,
      user_type: "User",
    },
  });
  console.log("SDsd", allUsers);
  //   console.log(allPeople?.data, "allPeople data", allPeople?.loading);

  return (
    <div className={styles.container} onClick={(e) => setShowDrawer(false)}>
      {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}
      {window.innerWidth > 900 ? (
        <div className={styles.peopleWrapper}>
          <div className={styles.labelRow}>
            <div>Name</div>
            <div>Subscribers</div>
            <div>Subscriptions</div>
            <div>Signals</div>
            <div></div>
          </div>

          <div className={styles.allPeopleWrapper}>
            {allUsers.loading
              ? Array(5)
                  .fill("")
                  .map((item) => (
                    <div className={styles.userCard}>
                      <div className={styles.cardRow1}>
                        <div className={styles.leftSide}>
                          <Skeleton width={50} height={50} borderRadius={50} />
                          <div>
                            <Skeleton width={100} height={10} />
                            <Skeleton width={70} height={8} />
                          </div>
                        </div>
                        <div className={styles.rightSide}>
                          <div className={styles.followingCount}>
                            <Skeleton width={120} height={10} />
                          </div>
                          <div className={styles.followingText}>
                            <Skeleton width={40} height={10} />
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRow3}>
                        <Skeleton width={200} height={10} />
                      </div>
                    </div>
                  ))
              : allUsers.data.get_all_user_types.length > 0
              ? allUsers.data.get_all_user_types.map((item, index) => {
                  return (
                    <div className={styles.userCard}>
                      <div className={styles.cardRow1}>
                        <div className={styles.leftSide}>
                          <div className={styles.userContent}>
                            <img
                              src={
                                item.profile_image
                                  ? item.profile_image
                                  : APP_LOGO_COLOR
                              }
                              alt=""
                              className={styles.userImage}
                            />
                            <div>
                              <div className={styles.userName}>{item.name}</div>
                              <div className={styles.userTag}>
                                @{item.username}
                              </div>
                            </div>
                          </div>
                          <div className={styles.itemsCount}>
                            {item.my_follower_count}
                          </div>
                          <div className={styles.itemsCount}>
                            {item.tiers_count}
                          </div>
                          <div className={styles.itemsCount}>0</div>
                          <div className={styles.btnCenter}>
                            <div className={styles.percentageBtn}>follow</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRow2}>{item.bio}</div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      ) : (
        <div className={styles.peopleWrapper}>
          <div className={styles.allPeopleWrapper}>
            {allUsers.loading
              ? Array(5)
                  .fill("")
                  .map((item) => (
                    <div className={styles.userCard}>
                      <div className={styles.cardRow1}>
                        <div className={styles.leftSide}>
                          <Skeleton width={50} height={50} borderRadius={50} />
                          <div>
                            <Skeleton width={100} height={10} />
                            <Skeleton width={70} height={8} />
                          </div>
                        </div>
                        <div className={styles.rightSide}>
                          <div className={styles.followingCount}>
                            <Skeleton width={120} height={10} />
                          </div>
                          <div className={styles.followingText}>
                            <Skeleton width={40} height={10} />
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRow3}>
                        <Skeleton width={200} height={10} />
                      </div>
                    </div>
                  ))
              : allUsers.data.get_all_user_types.length > 0
              ? allUsers.data.get_all_user_types.map((item, index) => {
                  return (
                    <div className={styles.userCard}>
                      <div className={styles.cardRow1}>
                        <div className={styles.leftSide}>
                          <div className={styles.userContent}>
                            <img
                              src={
                                item.profile_image
                                  ? item.profile_image
                                  : APP_LOGO_COLOR
                              }
                              alt=""
                              className={styles.userImage}
                            />
                            <div>
                              <div className={styles.userName}>{item.name}</div>
                              <div className={styles.userTag}>
                                @{item.username}
                              </div>
                            </div>
                          </div>
                          <div className={styles.btnCenter}>
                            <div className={styles.percentageBtn}>follow</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRow3}>{item.bio}</div>.
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}>
                            <div className={styles.itemsCount}>
                              {item.my_follower_count}
                            </div>
                            <div className={styles.itemsCount2}>
                              Subscribers
                            </div>
                          </div>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}>
                            <div className={styles.itemsCount}>
                              {item.tiers_count}
                            </div>
                            <div className={styles.itemsCount2}>
                              Subscriptions
                            </div>
                          </div>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}>
                            <div className={styles.itemsCount}>0</div>
                            <div className={styles.itemsCount2}>Signals</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default People;

import React, { useContext } from "react";

import styles from "./people.module.scss";

//packages
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "react-loading-skeleton";
import LoggedOutMenuSidebar from "../../components/LoggedOutMenuSidebar";
import { MyContext } from "../../Context";
import { APP_CODE, APP_LOGO_COLOR } from "../../config/appConfig";

const People = () => {
  const { setShowDrawer } = useContext(MyContext);

  const GET_ALL_USERS = gql`
    query ($upline_appcode: String, $user_type: String) {
      get_all_user_types(
        data: { upline_appcode: $upline_appcode, user_type: $user_type }
      ) {
        email
        username
        name
        bio
        profile_image
        following_count
      }
    }
  `;

  const allUsers = useQuery(GET_ALL_USERS, {
    variables: {
      upline_appcode: APP_CODE,
      user_type: "User",
    },
  });
  //   console.log(allPeople?.data, "allPeople data", allPeople?.loading);

  return (
    <div className={styles.container} onClick={(e) => setShowDrawer(false)}>
      {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}
      <div className={styles.peopleWrapper}>
        <div className={styles.labelRow}>
          <div>Name</div>
          <div>Following</div>
        </div>

        <div className={styles.allPeopleWrapper}>
          {allUsers.loading
            ? Array(5)
                .fill("")
                .map((item) => (
                  <div className={styles.userCard}>
                    <div className={styles.cardRow1}>
                      <div className={styles.leftSide}>
                        <Skeleton width={50} height={50} borderRadius={50} />
                        <div>
                          <Skeleton width={100} height={10} />
                          <Skeleton width={70} height={8} />
                        </div>
                      </div>
                      <div className={styles.rightSide}>
                        <div className={styles.followingCount}>
                          <Skeleton width={120} height={10} />
                        </div>
                        <div className={styles.followingText}>
                          <Skeleton width={40} height={10} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.cardRow2}>
                      <Skeleton width={200} height={10} />
                    </div>
                  </div>
                ))
            : allUsers.data.get_all_user_types.length > 0
            ? allUsers.data.get_all_user_types.map((item, index) => {
                return (
                  <div className={styles.userCard}>
                    <div className={styles.cardRow1}>
                      <div className={styles.leftSide}>
                        <img
                          src={
                            item.profile_image
                              ? item.profile_image
                              : APP_LOGO_COLOR
                          }
                          alt=""
                          className={styles.userImage}
                        />
                        <div>
                          <div className={styles.userName}>{item.name}</div>
                          <div className={styles.userTag}>@{item.username}</div>
                        </div>
                      </div>
                      <div className={styles.rightSide}>
                        <div className={styles.followingCount}>
                          {item.following_count}
                        </div>
                        <div className={styles.followingText}>Following</div>
                      </div>
                    </div>
                    <div className={styles.cardRow2}>{item.bio}</div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default People;

import React, { useContext, useState } from "react";

//assets
import leftArrowIcon from "../../assets/images/dropdownleftarrow.svg";
import rightArrowIcon from "../../assets/images/dropdownrightarrow.svg";
import { MyContext } from "../../Context";

const ExplorePost = () => {
  const { tokenValid } = useContext(MyContext);
  const [explorePost, setExplorePost] = useState([
    "Your Subscriptions",
    "Financial Media",
    "Your Investments",
  ]);
  const [currentExplore, setCurrentExplore] = useState(0);

  function changeExplore(condition) {
    if (condition === "add") {
      if (currentExplore === 2) {
        setCurrentExplore(0);
      } else {
        setCurrentExplore((props) => props + 1);
      }
    } else {
      if (currentExplore === 0) {
        setCurrentExplore(2);
      } else {
        setCurrentExplore((props) => props - 1);
      }
    }
  }

  return (
    <div className="explorepost">
      {window.innerWidth < 600 ? (
        <div>Show From</div>
      ) : (
        <div>Explore Posts From</div>
      )}

      <div className="changePostType">
        <img
          src={leftArrowIcon}
          alt="leftArrowIcon"
          onClick={() => {
            if (tokenValid) {
              changeExplore("sub");
            }
          }}
        />
        {tokenValid ? (
          <span>{explorePost[currentExplore]}</span>
        ) : (
          <span>Free Subscriptions</span>
        )}
        <img
          src={rightArrowIcon}
          alt="rightArrowIcon"
          onClick={() => {
            if (tokenValid) {
              changeExplore("add");
            }
          }}
        />
      </div>
    </div>
  );
};

export default ExplorePost;

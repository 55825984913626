import React, { useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";
import { APP_COLOR, APP_LOGO_COLOR } from "../../config/appConfig";

const Step2 = ({ setStep, setCountry }) => {
  useEffect(() => {
    console.log("Step 1 mounted", window.location.href.split("/").pop());
  }, []);

  const handleNext = (country) => {
    if (country) {
      setCountry(country);
      setStep(3);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img
            src={APP_LOGO_COLOR}
            alt=""
            style={{ width: "40px", height: "40px" }}
          />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Country Of Residence</h1>
              <h4 className={styles.subTitle}>Where are you from?</h4>
            </div>
            <div className={styles.form}>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Indian")}
              >
                I Am A Resident Of India
              </div>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Not Indian")}
              >
                I Am Not A Resident Of India
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide} style={{ background: APP_COLOR }}>
          <div className={styles.finishedPage} onClick={(e) => setStep(1)}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Country Of Residence</div>
          <div className={styles.otherPage}>Login Credentials</div>
          <div className={styles.otherPage}>Verify Email</div>
          <div className={styles.otherPage}>Enter Personal Details</div>
          <div className={styles.otherPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step2;

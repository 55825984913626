import React, { useEffect } from "react";
import styles from "./steps.module.scss";

import OtpInput from "react-otp-input";

import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";
import { APP_COLOR, APP_LOGO_COLOR } from "../../config/appConfig";

const Step4 = ({ setStep, emailOtp, setEmailOtp, emailVerification }) => {
  const handleNext = () => {
    if (emailOtp.length === 6) {
      emailVerification();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img
            src={APP_LOGO_COLOR}
            alt=""
            style={{ width: "40px", height: "40px" }}
          />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Verify Your Email</h1>
              <h4 className={styles.subTitle}>
                Please enter the six digit code that we just sent to *********
              </h4>
            </div>
            <div className={styles.form}>
              <div className={styles.otpView}>
                <OtpInput
                  value={emailOtp}
                  onChange={setEmailOtp}
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  shouldAutoFocus
                  containerStyle={styles.otpInputWrapper}
                  inputStyle={styles.otpInput}
                />
              </div>
              <div style={{ fontSize: "1.7vh" }}>
                Resend Code In{" "}
                <span style={{ fontWeight: "bold" }}>30 Seconds</span>
              </div>
            </div>
            <button
              className={styles.submitButton}
              style={{
                marginTop: "7vh",
                opacity: emailOtp.length === 6 ? 1 : 0.5,
                background: APP_COLOR,
              }}
              onClick={handleNext}
            >
              Proceed
            </button>
          </div>
        </div>
        <div className={styles.rightSide} style={{ background: APP_COLOR }}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Verify Email</div>
          <div className={styles.otherPage}>Enter Personal Details</div>
          <div className={styles.otherPage}>Verify Phone Number</div>
          <div className={styles.otherPage}>Enter Address</div>
          <div className={styles.otherPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step4;

import React, { useContext, useEffect } from "react";
import { Col, Empty, Spin, Tag, Card, Divider } from "antd";

import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { MyContext } from "../Context";
import PostComponent from "../components/PostComponent";
import { BookOutlined } from "@ant-design/icons";
import { APP_CODE } from "../config/appConfig";

const ALL_TRENDS = gql`
  query Trends($uplineAppcode: String) {
    trends(upline_appcode: $uplineAppcode) {
      id
      name
      post_count
    }
  }
`;
const MarketPage = () => {
  const {
    newsFeed,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    userEmail,
    mySavedPosts,
  } = useContext(MyContext);

  const allTrends = useQuery(ALL_TRENDS, {
    variables: {
      uplineAppcode: APP_CODE,
    },
  });

  const showTrends = () => {
    if (allTrends.data) {
      if (allTrends.data.trends.length > 0) {
        return (
          allTrends.data.trends
            // .sort((a, b) => (a.post_count < b.post_count ? 1 : -1))
            .map((item, index) => {
              if (index < 10 && item.post_count > 0) {
                return (
                  <div>
                    <p
                      onClick={() => {
                        // setTrendClicked(true);
                        // setSelectedTrend({
                        //   name: item.name,
                        //   count: item.post_count,
                        // });
                        // setTrendPostCount(item.post_count);
                        getPostsOfTrend({
                          variables: {
                            id: item.id,
                            user_id: localStorage.getItem("pulse_user_id"),
                          },
                        });
                      }}
                      style={{
                        margin: "0px",
                        cursor: "pointer",
                        // padding: "5px",
                        // paddingLeft: "25px"
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 20px",
                      }}
                    >
                      <span>#{item.name}</span>
                      <span>
                        <Tag>{item.post_count}</Tag>
                      </span>
                    </p>
                    {index !== 9 ? (
                      allTrends.data.trends.length !== index + 1 ? (
                        <Divider dashed style={{ margin: "5px 0px" }} />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {/* {index !== 9 ? (
                    <Divider dashed style={{ margin: "5px 0px" }} />
                  ) : (
                    <Empty />
                  )} */}
                  </div>
                );
              }
            })
        );
      } else {
        return <Empty />;
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  return (
    <>
      <Col
        xs={24}
        sm={14}
        style={{
          borderRight: "0.5px solid #EDEDED",
          background: "#ECF0F1",
          // background: "white",
          overflowY: "scroll",
          height: "100vh",
          padding: " 1px",
        }}
      >
        {/* <div style={{ padding: " 20px" }}>
          {mySavedPosts.data.get_my_bookmarked_posts.length > 0 ? (
            mySavedPosts.data.get_my_bookmarked_posts
              .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
              .map((item, index) => (
                <PostComponent
                  item={item}
                  index={index}
                  userInfo={userInfo.data ? userInfo : ""}
                />
              ))
          ) : (
            <Empty />
          )}
        </div> */}

        <div style={{ padding: " 20px" }}>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            <BookOutlined />
            &nbsp; Saved Posts:
          </div>
          <div>
            {mySavedPosts.data !== undefined ? (
              mySavedPosts.data.get_my_bookmarked_posts.length > 0 ? (
                mySavedPosts.data.get_my_bookmarked_posts
                  .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
                  .map((item, index) => (
                    <PostComponent
                      item={item}
                      index={index}
                      userInfo={userInfo.data ? userInfo : ""}
                    />
                  ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No Bookmarks Found</span>}
                  />
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30vh",
                }}
              >
                <Spin />
              </div>
            )}
          </div>
        </div>
      </Col>
      {window.innerWidth > 900 ? (
        <Col span={6}>
          <Card
            title="Recent Trends"
            // extra={<Icon type="setting" />}
            // style={{
            //   borderRadius: "8px",
            // }}
            bodyStyle={{
              padding: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {showTrends()}
          </Card>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default MarketPage;

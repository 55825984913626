import { Col, Empty, Row, Spin } from "antd";
import React, { useState, useContext, useEffect } from "react";
import MenuSidebar from "../components/MenuSidebar";
import CreatePostComponent from "../components/CreatePostComponent";

import { MyContext } from "../Context";
import PostComponent from "../components/PostComponent";
import Axios from "axios";
import Nav from "../components/Nav";
import LoggedOutMenuSidebar from "../components/LoggedOutMenuSidebar";
import ExplorePost from "../components/Minicomponents/ExplorePost";
import LoggedoutPostComponent from "../components/LoggedoutPostComponent";
import Alltrends from "../components/AllTrends";

import { ReactComponent as Feed_img } from "../assets/images/mobileSidebar/feed.svg";
import { ReactComponent as Trending_img } from "../assets/images/mobileSidebar/trending.svg";
import { ReactComponent as Winners_img } from "../assets/images/mobileSidebar/winners.svg";
import { ReactComponent as Calendar_img } from "../assets/images/mobileSidebar/calendar.svg";

const LoggedoutHome = () => {
  const {
    newsFeed,
    trendClicked,
    getPostsOfTrend,
    resTrendPosts,
    userInfo,
    allPosts,
    allUserData,
    allPostTrends,
    showMenu,
    showDrawer,
    setShowDrawer,
  } = useContext(MyContext);

  const [selectedBottomNav, setSelectedBottomNav] = useState("Home");

  const showConditionalPosts = () => {
    console.log("newsfeed", newsFeed.data);
    if (resTrendPosts.data && trendClicked) {
      // console.log("jhkjhkjhk", value.resTrendPosts.data);
      return resTrendPosts.data ? (
        resTrendPosts.data.user_choosen_trend
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <p>No Posts</p>
        </div>
      );
    } else if (newsFeed.data && !trendClicked) {
      if (newsFeed.data.get_my_home_posts.length > 0) {
        return newsFeed.data.get_my_home_posts
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((item, index) => (
            <PostComponent
              item={item}
              index={index}
              userInfo={userInfo.data ? userInfo : ""}
            />
          ));
      } else {
        return (
          <div style={{ padding: "20px" }}>
            <Empty
              description={
                <span>
                  Either you are not subscribed to anyone <br />
                  or there are no posts to show
                </span>
              }
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <Spin />
        </div>
      );
    }
  };

  const bottomNav = [
    {
      name: "Home",
      icon: <Feed_img />,
    },
    {
      name: "Trending",
      icon: <Trending_img />,
    },
    {
      name: "Winners",
      icon: <Winners_img />,
    },
    {
      name: "Calendar",
      icon: <Calendar_img />,
    },
  ];

  return (
    <div onClick={(e) => setShowDrawer(false)}>
      <Row style={{ height: "calc(100%-150px)" }}>
        {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}
        {!showMenu ? (
          <Col
            xs={24}
            sm={14}
            style={{
              // borderRight: "0.5px solid #EDEDED",
              // background: "#ECF0F1",
              // background: "white",
              overflowY: !showDrawer ? "scroll" : null,
              height :"80vh",
              padding: " 1px",
            }}
          >
            {/* <CreatePostComponent /> */}
            {allPosts ? (
              allPosts.length > 0 ? (
                <div style={{ padding: " 20px" }}>
                  <ExplorePost />
                  {allPosts ? (
                    allPosts
                      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
                      .map((item, index) => {
                        return (
                          <>
                            {allPostTrends ? (
                              <LoggedoutPostComponent
                                item={item}
                                index={index}
                                userInfo={allUserData ? allUserData[0] : ""}
                                author={allUserData[item.author_id]}
                                trends={allPostTrends}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                  ) : (
                    <Spin />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                  }}
                >
                  <Spin />
                  <div style={{ paddingTop: "-10px" }}>
                    {" "}
                    &nbsp;&nbsp;Loading Posts...
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        ) : (
          ""
        )}
        {window.innerWidth >700 ? (
           <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
           <Alltrends />
         </Col>
        ) : ""

        }
       
      </Row>
      {window.innerWidth < 700 ? (
        <div className="mobileBottomNav">
          {bottomNav.map((item) => {
            return (
              <div
                className="bottomNavItem"
                onClick={(e) => setSelectedBottomNav(item.name)}
              >
                {item.icon
                  ? React.cloneElement(item.icon, {
                      fill:
                        selectedBottomNav === item.name ? "white" : "#999999",
                    })
                  : ""}
                <div
                  style={{
                    color:
                      selectedBottomNav === item.name ? "white" : "#999999",
                  }}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default LoggedoutHome;

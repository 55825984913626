import React, { useEffect } from "react";
import styles from "./steps.module.scss";
import insiderIcon from "../../img/insiderIconColor.svg";
import checkRounded from "../../img/checkRounded.svg";
import { APP_COLOR } from "../../config/appConfig";

const Step8 = ({ setStep, setInvestorType }) => {
  useEffect(() => {
    console.log("Step 1 mounted", window.location.href.split("/").pop());
  }, []);

  const handleNext = (investorType) => {
    if (investorType) {
      setInvestorType(investorType);
      setStep(9);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img src={insiderIcon} alt="" />
          <div className={styles.formContainer}>
            <div>
              <h1 className={styles.title}>Type Of Investor?</h1>
              <h4 className={styles.subTitle}>
                What level of experience do you have?
              </h4>
            </div>
            <div className={styles.form}>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Retail")}
              >
                Retail Investor
              </div>
              <div
                className={styles.optionCard}
                onClick={(e) => handleNext("Accredited")}
              >
                Accredited Investor
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide} style={{ background: APP_COLOR }}>
          <div className={styles.finishedPage}>
            <div>Who Invited You</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Country Of Residence</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Login Credentials</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Email</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Personal Details</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Verify Phone Number</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.finishedPage}>
            <div>Enter Address</div>
            <img src={checkRounded} alt="" />
          </div>
          <div className={styles.currentPage}>Type Of Investor</div>
          <div className={styles.otherPage}>Type of Insider</div>
        </div>
      </div>
    </>
  );
};

export default Step8;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useNavigate, Link, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import moment from "moment";
import styles from "./loggedOutPost.module.scss";
import {
  Divider,
  Avatar,
  Tag,
  Icon,
  Button,
  Progress,
  Card,
  Input,
  Spin,
  Col,
  Row,
} from "antd";
import prof from "../../img/user.svg";
import lockedImg from "../../img/blurry.jpeg";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { MyContext } from "../../Context";
import backIcon from "../../assets/images/back.svg";

import {
  PlusOutlined,
  ArrowLeftOutlined,
  LockFilled,
  LikeOutlined,
  LoadingOutlined,
  SaveOutlined,
  CopyOutlined,
  LinkOutlined,
  CommentOutlined,
} from "@ant-design/icons";

import Axios from "axios";
import { BackspaceOutlined, ShareOutlined } from "@material-ui/icons";
import { timeCalculator } from "../../utils/FunctionTools";

import { ReactComponent as Feed_img } from "../../assets/images/mobileSidebar/feed.svg";
import { ReactComponent as Trending_img } from "../../assets/images/mobileSidebar/trending.svg";
import { ReactComponent as Winners_img } from "../../assets/images/mobileSidebar/winners.svg";
import { ReactComponent as Calendar_img } from "../../assets/images/mobileSidebar/calendar.svg";

const LIKE_POST = gql`
  mutation lkpost($id: ID!, $email: String!, $dislikeflag: Boolean!) {
    like_dislike_for_post(
      data: { post_id: $id, email: $email }
      dislike: $dislikeflag
    )
  }
`;

const CREATE_COMMENT = gql`
  mutation cmt($id: ID!, $email: String!, $text: String!) {
    create_comment(data: { post_id: $id, email: $email, text: $text }) {
      like_count
      text
      id
    }
  }
`;

const GET_COMMENT = gql`
  query getcmt($email: ID!, $id: ID!) {
    get_comments_of_a_post(data: { email: $email, post_id: $id }) {
      id
      like_count
      text
      timestamp
      author {
        name
        profile_image
      }
    }
  }
`;

const GET_POST = gql`
  query getPost($user_id: ID!, $id: ID!) {
    get_single_post(data: { user_id: $user_id, post_id: $id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const LoggedOutPost = () => {
  const { postid } = useParams();
  const { scrollRef, scrollPercentage, loadingProfile, setLoadingProfile } =
    useContext(MyContext);
  const navigate = useNavigate();
  const postBodyRef = useRef();

  const [showCommentArr, setShowCommentArr] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [postComments, setPostComments] = useState([]);

  const [likedUsers, setLikedUsers] = useState([]);
  const [userComments, setUserComments] = useState();

  const [visibleProfileCard, setVisibleProfileCard] = useState(false);

  const [likeDislikePost, likeDislikePostData] = useMutation(LIKE_POST);
  const [commentOnPost, commentOnPostData] = useMutation(CREATE_COMMENT);
  const [getComment, getCommentData] = useLazyQuery(GET_COMMENT);

  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [trendData, setTrendData] = useState(null);
  const [authorData, setAuthorData] = useState(null);
  const [selectedBottomNav, setSelectedBottomNav] = useState("Home");

  const ACTIONS = [
    {
      name: "Share",
      icon: <ShareOutlined />,
    },
    {
      name: "Like",
      icon: <LikeOutlined />,
    },
    {
      name: "Save",
      icon: <SaveOutlined />,
    },
    {
      name: "Copy",
      icon: <CopyOutlined />,
    },
  ];
  const bottomNav = [
    {
      name: "Share",
      icon: <ShareOutlined style={{ width: "13px", height: "13px" }} />,
    },
    {
      name: "Like",
      icon: <LikeOutlined style={{ width: "13px", height: "13px" }} />,
    },
    {
      name: "Save",
      icon: <SaveOutlined style={{ width: "13px", height: "13px" }} />,
    },
    {
      name: "Copy",
      icon: <CopyOutlined style={{ width: "13px", height: "13px" }} />,
    },
  ];
  useEffect(() => {
    console.log(window.location.href.split("/").pop(), "postidddd");
    setLoadingProfile(true);
    const postID = Axios.get(
      `https://connectionsapi.globalxchange.io/get_single_post?id=${window.location.href
        .split("/")
        .pop()}`
    ).then((res) => {
      console.log(
        res.data.payload.posts_data[0],
        res.data.payload,
        "postiddddxx"
      );
      setPostData(res.data.payload.posts_data[0]);
      setUserData(res.data.payload.user_data);
      setTrendData(res.data.payload.trends);
      setAuthorData(res.data.payload.author_data);
      setLoadingProfile(false);
    });
  }, []);

  useEffect(() => {
    if (likeDislikePostData.data) {
      setLikedUsers(likeDislikePostData.data.like_dislike_for_post);
      console.log(
        "likedusrers",
        likeDislikePostData.data.like_dislike_for_post
      );
    }
    // console.log(likeDislikePostData.data.like_dislike_for_post);
  }, [likeDislikePostData.data]);

  useEffect(() => {
    console.log("likedusrers");
  });

  const showComments = () => {
    return (
      <Card>
        {getCommentData.data
          ? getCommentData.data.get_comments_of_a_post.map((item) => {
              return (
                <>
                  <div style={{ display: "flex" }}>
                    <div>
                      {item.author.profile_image !== null ? (
                        <Avatar
                          // style={{ backgroundColor: "#87d068" }}
                          // icon="user"
                          src={item.author.profile_image}
                          size={30}
                        />
                      ) : (
                        <Avatar src={prof} size={30} />
                      )}
                    </div>
                    &nbsp;
                    <div>
                      <p
                        style={{
                          backgroundColor: "#F5F5F5",
                          padding: "5px",
                          borderRadius: "8px",
                        }}
                      >
                        <span style={{ color: "#40A9FF", fontWeight: "bold" }}>
                          {item.author.name}
                        </span>
                        : {item.text}
                      </p>
                      {/* <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "-10px"
                        }}
                      >
                        <div onClick={e => increaseCommentCount(item)}>
                          Like
                        </div>
                        <div>
                          {item.liked_users.length > 0 ? (
                            <Tag
                              color="geekblue"
                              onClick={e => showCommentedUsers(item)}
                            >
                              
                              &nbsp; {item.liked_users.length}
                            </Tag>
                          ) : (
                            ""
                          )}
                        </div>
                      </p>
                     */}
                    </div>
                  </div>
                </>
              );
            })
          : ""}

        <div style={{ display: "flex" }}>
          {/* <div>
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              icon="user"
              size={30}
            />
          </div>
          &nbsp; */}
          <div style={{ width: "100%" }}>
            <Input
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              type="text"
              placeholder="write a comment..."
              style={{ borderRadius: "100px" }}
            />
          </div>
          &nbsp;
          <div>
            <Button
              shape="circle"
              onClick={(e) => {
                commentOnPost({
                  variables: {
                    id: getPost.data ? getPost.data.get_single_post.id : "",
                    email: localStorage.getItem("user_account"),
                    text: commentInput,
                  },
                  refetchQueries: [
                    {
                      query: GET_COMMENT,
                      variables: {
                        email: localStorage.getItem("user_account"),
                        id: getPost.data ? getPost.data.get_single_post.id : "",
                      },
                    },
                  ],
                  awaitRefetchQueries: true,
                });
                setCommentInput("");
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const leftSideData = () => {
    return (
      <Col span={12}>
        <div
          className="makeacard"
          ref={scrollRef}
          style={{
            //   padding: "70px 100px",
            overflowY: "scroll",
            width: "100%",
            minHeight: "90vh",
          }}
        >
          {/* <div style={{ position: "fixed", top: "80px", right: "0px" }}>
    <Progress
      type="circle"
      percent={scrollPercentage}
      format={percent => `${percent} %`}
    />
  </div> */}

          <div
            style={{
              margin: "10px 10px",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "15px",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowLeftOutlined
                  style={{
                    display: "block",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "9px",
                  }}
                  onClick={(e) => navigate("/")}
                />
                <span
                  style={{
                    display: "block",
                  }}
                >
                  &nbsp;
                </span>
                &nbsp;
                {userData ? (
                  userData[postData.author_id] ? (
                    <Avatar
                      src={userData[postData.author_id].profile_image}
                      size={40}
                    />
                  ) : (
                    <Avatar src={prof} size={40} />
                  )
                ) : (
                  ""
                )}
                &nbsp;&nbsp;
                <Link to={`/${userData[postData.author_id].username}`}>
                  <div
                    onClick={(e) => {
                      localStorage.setItem(
                        "user_profile_email",
                        userData[postData.author_id].email
                      );
                    }}
                  >
                    <h5 style={{ margin: "0px", cursor: "pointer" }}>
                      {userData[postData.author_id].username}
                    </h5>
                    <small>
                      {new Date(Number(postData.timestamp)).toDateString()}
                      {/* {new Date(Number(item.timestamp)).toLocaleString()} */}
                    </small>
                  </div>
                </Link>
              </div>
              <div>
                {/* <Tag>{item.post_tier[0].name}</Tag> */}
                <Tag>Trend</Tag>
                {/* <Icon type="setting" style={{ fontSize: "15px" }} /> */}
              </div>
            </div>
            <Divider style={{ margin: "5px 0px" }} />

            <h3 style={{ padding: "10px 20px", margin: "0px" }}>
              {postData.title}
            </h3>

            <div
              ref={postBodyRef}
              className="postbody"
              style={{
                padding: "10px 20px",
              }}
            >
              {!postData.locked ? (
                <>
                  <div>{ReactHtmlParser(JSON.parse(postData.body))}</div>
                  {postData.is_this_non_course_video_post === true ? (
                    <Link
                      to={`/videos/${postData.id}`}
                      style={{ color: "#000000a6" }}
                    >
                      <Card
                        bodyStyle={{ padding: "10px" }}
                        className="shadow"
                        style={{
                          borderRadius: "8px",
                          marginTop: "10px",
                          borderColor: "#F5F5F5",
                        }}
                      >
                        <div style={{ display: "flex", padding: "10px" }}>
                          <div>
                            <img
                              src={postData.thumbnail}
                              alt=""
                              style={{ width: "200px" }}
                            />
                          </div>
                          <div style={{ padding: "0px 10px" }}>
                            <div>
                              <h4>{postData.video_title}</h4>
                            </div>

                            <p
                              style={{
                                color: "darkgray",
                                marginTop: "10px",
                              }}
                            >
                              {postData.video_description.substring(0, 200)}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${lockedImg})`,
                    backgroundSize: "cover",
                    textAlign: "center",
                    padding: "40px 0px",
                  }}
                >
                  <LockFilled style={{ fontSize: "40px", color: "white" }} />

                  <h4 style={{ color: "white" }}>This is a Premium Post</h4>
                  <h6>
                    Please Subscribe to {userData[postData.author_id].username}{" "}
                    to see this post
                  </h6>
                  <Link to={`/${userData[postData.author_id].username}`}>
                    <Button
                      onClick={(e) => {
                        localStorage.setItem(
                          "user_profile_email",
                          userData[postData.author_id].email
                        );
                      }}
                      style={{
                        borderRadius: "20px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      Subscribe
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <Divider style={{ margin: "0px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <span
              // style={{
              //   color:
              //     likedUsers.indexOf(
              //       localStorage.getItem("pulse_user_id")
              //     ) > -1
              //       ? "blue"
              //       : ""
              // }}
              //   onClick={(e) => handleLike(item)}
              >
                {!likeDislikePostData.loading ? (
                  <LikeOutlined key="like" />
                ) : (
                  <LoadingOutlined key="like" />
                )}
                &nbsp; Like ({likedUsers ? likedUsers.length : 0})
              </span>
              {/* 
              <span
                onClick={e => {
                  toggle(item.id);
                }}
              >
                <Icon type="edit" key="edit" />
                &nbsp; Comment (
                {getCommentData.data
                  ? getCommentData.data.get_comments_of_a_post.length
                  : item.comment_count}
                )
              </span>
          */}
            </div>
            {/* {showComments()} */}
          </div>
        </div>
      </Col>
    );
  };

  const getPost = useQuery(GET_POST, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      id: window.location.href.split("/").pop(),
    },
  });

  useEffect(() => {
    if (getPost.data) {
      setLikedUsers(getPost.data.get_single_post[0].liked_users);
    }
  }, [getPost.data]);

  useEffect(() => {
    console.log("my percentage", scrollPercentage);
  }, [scrollPercentage]);

  //   useEffect(() => {
  //     console.log(userData[postData.author_id].username, "jbwekjwe");
  //   });

  return (
    <>
      <div style={{ overflowY: window.innerWidth < 700 ? "scroll" : "auto" }}>
        {postData && userData ? (
          <div className={styles.postpageWrapper}>
            <div
              className={styles.leftSideLayout}
              style={{
                height:
                  window.innerWidth > 700 ? window.innerHeight - 150 : "100%",
              }}
            >
              <div className={styles.goBackLink} onClick={(e) => navigate(-1)}>
                <img src={backIcon} alt="" />
                <div>Back To All Signals</div>
              </div>
              <div className={styles.postTitle}>{postData.title}</div>
              <div
                className={styles.postBody}
                style={{
                  height:
                    window.innerWidth > 700 ? window.innerHeight - 400 : "100%",
                }}
              >
                {ReactHtmlParser(JSON.parse(postData.body))}
              </div>

              <div className={styles.actionsWrapper}>
                {ACTIONS.map((item) => {
                  return (
                    <div className={styles.actionItem}>
                      <div className={styles.actionIcon}>{item.icon}</div>
                      <div className={styles.actionText}>{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.rightSideLayout}>
              <div className={styles.rightsideTopHalf}>
                <div>
                  <div className={styles.postCardHeader}>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={(e) =>
                        navigate(`/${authorData ? authorData?.username : ""}`)
                      }
                    >
                      {authorData ? (
                        authorData?.profile_image ? (
                          <Avatar
                            src={
                              postData?.author
                                ? postData?.author?.profile_image
                                : userData
                                ? userData?.profile_image
                                : prof
                            }
                            size={40}
                          />
                        ) : (
                          <Avatar src={prof} size={50} />
                        )
                      ) : (
                        ""
                        // <Avatar src={userData.profile_image} size={40} />
                      )}
                      &nbsp;&nbsp;
                      <div
                        onClick={(e) => {
                          localStorage.setItem(
                            "user_profile_email",
                            authorData.email
                          );
                          localStorage.setItem(
                            "selected_user_id",
                            authorData.id
                          );

                          navigate(
                            `/${authorData ? authorData?.username : ""}`
                          );
                        }}
                      >
                        <div className={styles.authorName}>
                          {authorData
                            ? authorData?.name
                            : userData
                            ? userData.name
                            : ""}
                        </div>
                        <div className={styles.timestamp}>
                          {timeCalculator(postData.timestamp)}
                        </div>
                      </div>
                    </div>
                    {window.innerWidth > 700 ? (
                      <div className={styles.rightSide}>
                        <div className={styles.likeStat}>
                          <div className={styles.likeItem}>
                            <LikeOutlined style={{ fontSize: "19px" }} />
                            <div>{postData.like_count}</div>
                          </div>
                          <div className={styles.likeItem}>
                            <CommentOutlined style={{ fontSize: "19px" }} />
                            <div>{postData.comment_count}</div>
                          </div>
                          <div className={styles.likeItem}>
                            <CopyOutlined style={{ fontSize: "19px" }} />
                            <div>--</div>
                          </div>
                        </div>
                        <div className={styles.followAndTierButtonGroup}>
                          <div>Follows</div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.rightSide}>
                        <div className={styles.followAndTierButtonGroup}>
                          <div>Follows</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className={styles.separatorRight} />
                </div>
                <div className={styles.commentContainer}>
                  {postData &&
                  postData.comments &&
                  postData.comments.length > 0 ? (
                    <div className={styles.commentDisplay}>
                      <Avatar
                        src={postData.comments[0].comment_user_profile_image}
                        size={40}
                      />
                      <div className={styles.commentDisplayItemMain}>
                        <div className={styles.commentDisplayItem1}>
                          <div className={styles.commentDisplayHeader}>
                            <div className={styles.commentDisplayAuthor}>
                              {postData.comments[0].comment_user_name}
                            </div>
                            <div>
                              <div className={styles.likeItemComment}>
                                {/* <LikeOutlined style={{ width: "8px", height: "8px" }}/> */}
                                {/* <div style={{ fontSize: "10px", marginLeft:"2px" }}>{postData.like_count}</div> */}
                                <div style={{ fontSize: "9px" }}>
                                  {moment(
                                    postData.comments[0].timestamp
                                  ).fromNow()}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* body */}
                          <div className={styles.commentDisplayBody}>
                            {postData.comments[0].text}
                          </div>
                        </div>
                        <div className={styles.commentDisplayItem2}>
                          <div>Like</div>
                          <div style={{ marginLeft: "15px" }}>Reply</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <hr className={styles.separatorRight2} />
                  <div className={styles.commentInput}>
                    Login To Write A Comment
                  </div>
                </div>
              </div>
              {/* <div className={styles.rightsideBottomHalf}>&nbsp;</div> */}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spin />
          </div>
        )}
      </div>
      <div className={styles.bottomNavWrapper}>
        {bottomNav.map((item) => {
          return (
            <div
              className={styles.bottomNavItem}
              onClick={(e) => setSelectedBottomNav(item.name)}
            >
              <div>{item.icon}</div>
              <div
                style={{
                  color: selectedBottomNav === item.name ? "white" : "#999999",
                }}
              >
                {item.name}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LoggedOutPost;

import React, { useContext, useEffect } from "react";
import styles from "./traders.module.scss";
import LoggedOutMenuSidebar from "../../components/LoggedOutMenuSidebar";

import leftArrowIcon from "../../assets/images/dropdownleftarrow.svg";
import rightArrowIcon from "../../assets/images/dropdownrightarrow.svg";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Skeleton from "react-loading-skeleton";
import { MoreOutlined } from "@ant-design/icons";
import { APP_CODE, APP_LOGO_COLOR } from "../../config/appConfig";

import chartLine from "../../assets/images/chartLine.svg";
import { MyContext } from "../../Context";

const Traders = () => {
  const { showDrawer, setShowDrawer } = useContext(MyContext);

  const GET_ALL_USERS = gql`
    query ($upline_appcode: String, $user_type: String) {
      get_all_user_types(
        data: { upline_appcode: $upline_appcode, user_type: $user_type }
      ) {
        email
        username
        name
        profile_image
        my_follower_count
      }
    }
  `;

  const allUsers = useQuery(GET_ALL_USERS, {
    variables: {
      upline_appcode: APP_CODE,
      user_type: "Trader",
    },
  });

  return (
    <div className={styles.container} onClick={(e) => setShowDrawer(false)}>
      {window.innerWidth > 900 ? <LoggedOutMenuSidebar /> : ""}
      <div className={styles.tradersWrapper}>
        <div className={styles.sortingRow}>
          <div>Sort By</div>
          <div className={styles.sortingParameters}>
            <img src={leftArrowIcon} alt="leftArrowIcon" />
            <div>Most Followers</div>
            <img src={rightArrowIcon} alt="rightArrowIcon" />
          </div>
        </div>
        <div className={styles.allUserWrapper}>
          {allUsers.loading
            ? Array(4)
                .fill("")
                .map((item) => (
                  <div className={styles.userCard}>
                    <div className={styles.cardRow1}>
                      <div>
                        <Skeleton width={30} height={10} />
                      </div>
                      <MoreOutlined />
                    </div>
                    <div className={styles.userDataRow}>
                      <Skeleton width={80} height={80} borderRadius={50} />
                      <div className={styles.useName}>
                        <Skeleton width={150} height={10} />
                      </div>
                    </div>
                    <div className={styles.userStat}>
                      <Skeleton width={80} height={10} />
                    </div>
                    <div
                      className={styles.btnCenter}
                      style={{ paddingBottom: "30px" }}
                    >
                      <div className={styles.percentageBtn}>
                        <Skeleton width={30} height={10} />
                      </div>
                    </div>
                  </div>
                ))
            : allUsers.data.get_all_user_types.length > 0
            ? allUsers.data.get_all_user_types.map((item, index) => {
                return (
                  <div className={styles.userCard}>
                    <div className={styles.cardRow1}>
                      <div>Risk 0</div>
                      <MoreOutlined />
                    </div>
                    <div className={styles.userDataRow}>
                      <img
                        src={
                          item.profile_image
                            ? item.profile_image
                            : APP_LOGO_COLOR
                        }
                        alt=""
                        className={styles.userImage}
                      />
                      <div className={styles.useName}>{item.name}</div>
                    </div>
                    <div className={styles.userStat}>
                      Copiers <span>{item?.my_follower_count}</span>
                      &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Capital
                      <span>0</span>
                    </div>
                    <div className={styles.btnCenter}>
                      <div className={styles.percentageBtn}>0.00%</div>
                    </div>
                    <div className={styles.chartWrapper}>
                      <img src={chartLine} alt="" />
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Traders;

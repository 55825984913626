import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useNavigate, Link, useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import {
  Divider,
  Avatar,
  Tag,
  Icon,
  Button,
  Progress,
  Card,
  Input,
  Spin,
  Col,
} from "antd";
import prof from "../img/user.svg";
import lockedImg from "../img/blurry.jpeg";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { MyContext } from "../Context";

import {
  PlusOutlined,
  ArrowLeftOutlined,
  LockFilled,
  LikeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const LIKE_POST = gql`
  mutation lkpost($id: ID!, $email: String!, $dislikeflag: Boolean!) {
    like_dislike_for_post(
      data: { post_id: $id, email: $email }
      dislike: $dislikeflag
    )
  }
`;

const CREATE_COMMENT = gql`
  mutation cmt($id: ID!, $email: String!, $text: String!) {
    create_comment(data: { post_id: $id, email: $email, text: $text }) {
      like_count
      text
      id
    }
  }
`;

const GET_COMMENT = gql`
  query getcmt($email: ID!, $id: ID!) {
    get_comments_of_a_post(data: { email: $email, post_id: $id }) {
      id
      like_count
      text
      timestamp
      author {
        name
        profile_image
      }
    }
  }
`;

const GET_POST = gql`
  query getPost($user_id: ID!, $id: ID!) {
    get_single_post(data: { user_id: $user_id, post_id: $id }) {
      id
      title
      body
      timestamp
      like_count
      post_tier {
        id
        name
      }
      comments {
        text
      }
      author {
        id
        username
        profile_image
        email
      }
      comment_count
      liked_users {
        id
        username
      }
      trends {
        id
        name
        post_count
      }
      is_this_non_course_video_post
      video_id
      thumbnail
      video_title
      video_subtitle
      video_description
    }
  }
`;

const Post = () => {
  const { postid } = useParams();
  const { scrollRef, scrollPercentage } = useContext(MyContext);
  const navigate = useNavigate();
  const postBodyRef = useRef();

  const [showCommentArr, setShowCommentArr] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [postComments, setPostComments] = useState([]);

  const [likedUsers, setLikedUsers] = useState([]);
  const [userComments, setUserComments] = useState();

  const [visibleProfileCard, setVisibleProfileCard] = useState(false);

  const [likeDislikePost, likeDislikePostData] = useMutation(LIKE_POST);
  const [commentOnPost, commentOnPostData] = useMutation(CREATE_COMMENT);
  const [getComment, getCommentData] = useLazyQuery(GET_COMMENT);

  useEffect(() => {
    if (likeDislikePostData.data) {
      setLikedUsers(likeDislikePostData.data.like_dislike_for_post);
      console.log(
        "likedusrers",
        likeDislikePostData.data.like_dislike_for_post
      );
    }
    // console.log(likeDislikePostData.data.like_dislike_for_post);
  }, [likeDislikePostData.data]);

  useEffect(() => {
    console.log("likedusrers");
  });

  const handleLike = (item) => {
    if (likedUsers.length > 0) {
      if (likedUsers.indexOf(localStorage.getItem("pulse_user_id")) > -1) {
        likeDislikePost({
          variables: {
            id: item.id,
            email: localStorage.getItem("user_account"),
            dislikeflag: true,
          },
        });
      } else {
        likeDislikePost({
          variables: {
            id: item.id,
            email: localStorage.getItem("user_account"),
            dislikeflag: false,
          },
        });
      }
    } else {
      likeDislikePost({
        variables: {
          id: item.id,
          email: localStorage.getItem("user_account"),
          dislikeflag: false,
        },
      });
    }

    // if (
    //   item.liked_users.find(o => o.id !== "2c506f5e2c4e5f30627fae7dc8b411bd") ||
    //   item.liked_users.find(
    //     o => o.id !== "2c506f5e2c4e5f30627fae7dc8b411bd"
    //   ) === undefined
    // ) {
    //   console.log("liked_old", item.liked_users);
    //
    // }
    // setLikeCount(Number(item.liked_users.length) + 1);
  };

  const showComments = () => {
    return (
      <Card>
        {getCommentData.data
          ? getCommentData.data.get_comments_of_a_post.map((item) => {
              return (
                <>
                  <div style={{ display: "flex" }}>
                    <div>
                      {item.author.profile_image !== null ? (
                        <Avatar
                          // style={{ backgroundColor: "#87d068" }}
                          // icon="user"
                          src={item.author.profile_image}
                          size={30}
                        />
                      ) : (
                        <Avatar src={prof} size={30} />
                      )}
                    </div>
                    &nbsp;
                    <div>
                      <p
                        style={{
                          backgroundColor: "#F5F5F5",
                          padding: "5px",
                          borderRadius: "8px",
                        }}
                      >
                        <span style={{ color: "#40A9FF", fontWeight: "bold" }}>
                          {item.author.name}
                        </span>
                        : {item.text}
                      </p>
                      {/* <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "-10px"
                        }}
                      >
                        <div onClick={e => increaseCommentCount(item)}>
                          Like
                        </div>
                        <div>
                          {item.liked_users.length > 0 ? (
                            <Tag
                              color="geekblue"
                              onClick={e => showCommentedUsers(item)}
                            >
                              
                              &nbsp; {item.liked_users.length}
                            </Tag>
                          ) : (
                            ""
                          )}
                        </div>
                      </p>
                     */}
                    </div>
                  </div>
                </>
              );
            })
          : ""}

        <div style={{ display: "flex" }}>
          {/* <div>
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              icon="user"
              size={30}
            />
          </div>
          &nbsp; */}
          <div style={{ width: "100%" }}>
            <Input
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              type="text"
              placeholder="write a comment..."
              style={{ borderRadius: "100px" }}
            />
          </div>
          &nbsp;
          <div>
            <Button
              shape="circle"
              onClick={(e) => {
                commentOnPost({
                  variables: {
                    id: getPost.data ? getPost.data.get_single_post.id : "",
                    email: localStorage.getItem("user_account"),
                    text: commentInput,
                  },
                  refetchQueries: [
                    {
                      query: GET_COMMENT,
                      variables: {
                        email: localStorage.getItem("user_account"),
                        id: getPost.data ? getPost.data.get_single_post.id : "",
                      },
                    },
                  ],
                  awaitRefetchQueries: true,
                });
                setCommentInput("");
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const getPost = useQuery(GET_POST, {
    variables: {
      user_id: localStorage.getItem("pulse_user_id"),
      id: window.location.href.split("/").pop(),
    },
  });

  useEffect(() => {
    if (getPost.data) {
      setLikedUsers(getPost.data.get_single_post[0].liked_users);
    }
  }, [getPost.data]);

  useEffect(() => {
    console.log("my percentage", scrollPercentage);
  }, [scrollPercentage]);

  return (
    <>
      <Col span={20}>
        <div
          ref={scrollRef}
          style={{
            //   padding: "70px 100px",
            width: "100%",
            minHeight: window.innerHeight - 100,
            overflowY: "scroll",
          }}
        >
          {getPost.data ? (
            getPost.data.get_single_post.map((item) => {
              return (
                <div
                  style={{
                    margin: "10px 10px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "15px",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ArrowLeftOutlined
                        style={{
                          display: "block",
                          // window?.navigate?.length > 1 ? "block" : "none",
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "9px",
                        }}
                        onClick={(e) => navigate("/")}
                      />
                      <span
                        style={{
                          display: "block",
                          // window.navigate.length > 1 ? "block" : "none",
                        }}
                      >
                        &nbsp;
                      </span>
                      {item.author ? (
                        item.author.profile_image ? (
                          <Avatar src={item.author.profile_image} size={40} />
                        ) : (
                          <Avatar src={prof} size={40} />
                        )
                      ) : (
                        ""
                      )}
                      &nbsp;&nbsp;
                      <Link to={`/${item.author.username}`}>
                        <div
                          onClick={(e) => {
                            localStorage.setItem(
                              "user_profile_email",
                              item.author.email
                            );
                          }}
                        >
                          <h5 style={{ margin: "0px", cursor: "pointer" }}>
                            {item.author.username}
                          </h5>
                          <small>
                            {new Date(Number(item.timestamp)).toDateString()}
                            {/* {new Date(Number(item.timestamp)).toLocaleString()} */}
                          </small>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Tag>{item.post_tier[0].name}</Tag>
                      {/* <Icon type="setting" style={{ fontSize: "15px" }} /> */}
                    </div>
                  </div>
                  <Divider style={{ margin: "5px 0px" }} />

                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {item.title}
                  </h4>

                  <div
                    ref={postBodyRef}
                    className="postbody"
                    style={{
                      padding: "10px 20px",
                    }}
                  >
                    {!item.locked ? (
                      <>
                        <div>{ReactHtmlParser(JSON.parse(item.body))}</div>
                        {item.is_this_non_course_video_post === true ? (
                          <Link
                            to={`/videos/${item.id}`}
                            style={{ color: "#000000a6" }}
                          >
                            <Card
                              bodyStyle={{ padding: "10px" }}
                              className="shadow"
                              style={{
                                borderRadius: "8px",
                                marginTop: "10px",
                                borderColor: "#F5F5F5",
                              }}
                            >
                              <div style={{ display: "flex", padding: "10px" }}>
                                <div>
                                  <img
                                    src={item.thumbnail}
                                    alt=""
                                    style={{ width: "200px" }}
                                  />
                                </div>
                                <div style={{ padding: "0px 10px" }}>
                                  <div>
                                    <h4>{item.video_title}</h4>
                                  </div>

                                  <p
                                    style={{
                                      color: "darkgray",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {item.video_description.substring(0, 200)}
                                  </p>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url(${lockedImg})`,
                          backgroundSize: "cover",
                          textAlign: "center",
                          padding: "40px 0px",
                        }}
                      >
                        <LockFilled
                          style={{ fontSize: "40px", color: "white" }}
                        />

                        <h4 style={{ color: "white" }}>
                          This is a Premium Post
                        </h4>
                        <h6>
                          Please Subscribe to {item.author.username} to see this
                          post
                        </h6>
                        <Link to={`/${item.author.username}`}>
                          <Button
                            onClick={(e) => {
                              localStorage.setItem(
                                "user_profile_email",
                                item.author.email
                              );
                            }}
                            style={{
                              borderRadius: "20px",
                              fontWeight: "bold",
                              marginTop: "10px",
                            }}
                          >
                            Subscribe
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <Divider style={{ margin: "0px" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "20px",
                    }}
                  >
                    <span
                      // style={{
                      //   color:
                      //     likedUsers.indexOf(
                      //       localStorage.getItem("pulse_user_id")
                      //     ) > -1
                      //       ? "blue"
                      //       : ""
                      // }}
                      onClick={(e) => handleLike(item)}
                    >
                      {!likeDislikePostData.loading ? (
                        <LikeOutlined key="like" />
                      ) : (
                        <LoadingOutlined key="like" />
                      )}
                      &nbsp; Like ({likedUsers ? likedUsers.length : 0})
                    </span>
                    {/* 
                    <span
                      onClick={e => {
                        toggle(item.id);
                      }}
                    >
                      <Icon type="edit" key="edit" />
                      &nbsp; Comment (
                      {getCommentData.data
                        ? getCommentData.data.get_comments_of_a_post.length
                        : item.comment_count}
                      )
                    </span>
                */}
                  </div>
                  {/* {showComments()} */}
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      </Col>
    </>
  );
};

export default Post;
